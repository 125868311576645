.review-card {
	position: relative;
	display: flex;
	border-radius: 8px;;
	background: $white;
	overflow: hidden;
	margin-bottom: 35px;
	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
	}

	.image-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $tartiary;
		padding: 10px;
		flex: 0 0 100%;

		@include media-breakpoint-up(lg) {
			flex: 0 0 185px;
		}

		.image {
			width: 110px;
			height: 110px;
			border-radius: 50%;
			border: 3px solid #88ace3;
		}
	}

	.review-details {
		position: relative;
		padding: 30px;
		width: 100%;
		box-shadow: 0 0 49px 0 rgba(0,0, 0, .08);
		.title-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;

			h4 {
				font-size: 22px;
				@include text-truncate;
				max-width: 320px;
			}
			.cta {
				display: flex;
				align-items: center;
			}

			.link {

				margin-left: 10px;;
				font-size: 16px;
				height: 30px;
				border: 1px solid $primary;
				font-weight: normal !important;
				border-radius: 30px;;
				padding-left: 10px;
				padding-right: 10px;
				font-family: $bfont;
				display: flex;
				align-items: center;

			}
		}
	}

}


.review-count {
	position: relative;
	display: flex;
	padding-top: 10px;
	padding-bottom: 15px;
	li {
		padding: 0 2px;
	}
	ul {
		margin-right: 20px;
	}

	span {
		display: flex;
		align-items: center;
		img {margin-right: 10px}
		font-size: 16px;
		color: #b9c0c9;
	}

	p {font-size: 18px;}
}

.recomend {
	font-weight: bold;
	padding-top: 20px;
	.yes {
		color: #5cb100;
	}
}


.recommend {
	position: relative;
	background: $white;
	padding: 10px;
	box-shadow: 0 0 49px 0 rgba(0,0, 0, .08);
	display: flex;
	border-radius: 4px;;
	margin-bottom: 35px;
	.single {
		flex-grow: 1;
		padding: 10px 0;
		text-align: center;
		border-right: 1px solid #b9c0c9;
		&:last-child {border-right: 0;}
		h4 {
			font-size: 30px;
			color: #1a5889;
		}

		p {
			font-size: 16px;
			font-weight: bold;
		}
	}
}

.overall {
	position: relative;
	width: 100%;
	padding: 20px;
	background: $white;
	box-shadow: 0 0 49px 0 rgba(0,0, 0, .08);

	.header {
		text-align: center;
		padding-bottom: 20px;
		margin-bottom: 15px;
		border-bottom: 1px solid #b9c0c9;
		h5 {
			text-transform: uppercase;
			padding-bottom: 10px;
			font-size: 16px;
			font-weight: bold;
			display: block;
		}
		h4 {
			font-size: 50px;
			color: $primary;
			padding-bottom: 15px;
			display: block;
		}
	}

	ul {
		align-items: center;;
		justify-content: center;;
	}
	li {
		padding: 0 2px;
	}

	.body {
		display: flex;
		flex-wrap: wrap;;
		.single {
			width: 50%;
			padding-top: 10px;
			padding-bottom: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			text-align: center;
			p {
				font-size: 16px;
				padding-bottom: 0px;
				text-align: center;
			}
			li {
				padding: 0 1px;
				img {
					width: 17px;
				}
			}
		}
	}
}
