/////////////////////////////////////////////////////////
// Home Page: /                                        //
/////////////////////////////////////////////////////////

// Overides for home page here
.home {}

.company-offers {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 30px;
		background: url('assets/img/regular/shape-secondary.png') center center no-repeat;
	}
}
