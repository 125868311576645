.latest-blog {
	.title {
		padding-bottom: 0;
		padding-top: 20px;
	}
}
.latest-blog-list {
	position: relative;
	display: flex;
	align-items: center;
	padding: 20px 0;
	.image {
		width: 86px;
		height: 86px;
		@include background-cover;
		flex:  0 0 86px;
		margin-right: 25px;
		border-radius: 50%;
	}
}

.cta a {
	font-size: 16px;
	font-family: $hfont;
	font-weight:600;
	@include media-breakpoint-up(xxl) {

		font-size: 24px;
	}
}
