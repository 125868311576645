.tab-container {
	position: relative;
}

.tabs {
	padding-right: 10px;
	margin-bottom: 30px;
	li a {
		padding: 20px 15px;
		font-size: 16px;
		font-weight: bold;
		display: block;
		background: $tartiary;
		color: $white;
		@include media-breakpoint-up(xxl) {
			padding: 25px 15px;
			font-size: 22px;
		}

		&.inactive {
			background: $line;
			color: $headingcolor;
			&:hover {
				background: darken($line, 5%);
			}
		}
	}
}

.tab-content p {
	margin-bottom: 30px;
}
