.card {
	position: relative;
	width: 100%;
	background: $white;
	border-radius: 8px;
	display: block;
	overflow: hidden;
	box-shadow: 0 0 49px 0 rgba(0,0, 0, .08);
	margin-bottom: 30px;
	@include media-breakpoint-up(xxl) {
		margin-bottom: 0;

	}

	.bg-block {
		//clip-path: circle(100% at 50% -50%);
		&.green {
			background: #3b7eff;
		}
		&.red {
			background: #ef5b51;
		}
		&.yellow {
			background: #fdad20;
			> img {
				margin-top: -20px;
			}
		}

		&.white-star {
			.stars a {
				background: $white;
				color: #fdad20;

			}
		}
		padding: 10px 20px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		width: 100%;
		height: 210px;
		> img {
			margin-bottom: 30px;
		}
		.clip-path {
			width: 200%;
			height: 200%;
			border: 40px solid #fff;
			position: absolute;
			bottom: -35px;
			left: -50%;
			border-radius: 50%;
			}

			.stars {
				li {padding: 0 3px;}

				a {
					width: 100px;
					height: 30px;
					background: #fdad20;
					text-transform: uppercase;
					font-weight: bold;
					color: $white;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 10px;;
					margin-left: 15px;
					font-size: 18px;
				}

			}
	}

	.details {
		padding: 30px;

		.author {
			font-weight: bold;
			padding-top: 5px;
		}
		a {
			font-size: 18px;
			text-align: right;
			display: block;
			font-weight: bold;
			margin-bottom: 15px;
		}
	}
}
