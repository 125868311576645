/////////////////////////////////////////////////////////
// Color                                               //
/////////////////////////////////////////////////////////

// SETUP
//// Base
$primary: #fdad20!default;
$secondary: #333333!default;
$tartiary: #0c4e82!default;
$black: #000000!default;
$darkgrey: #9B9B9B!default;
$lightgrey: #EEEEEE!default;
$white: #FFFFFF!default;
//// States
$error: #D0021B!default;
$warning: #F5A623!default;
$success: #7ED321!default;
$disabled: $lightgrey!default;
//// Defaults
$bodycolor: $secondary!default;
$headingcolor: $secondary!default;
$line: #f6f8fb!default;

//// Into an array
$colors: (
	primary: $primary,
	secondary: $secondary,
	tertiary: $tartiary,
	black: $black,
	darkgrey: $darkgrey,
	lightgrey: $lightgrey,
	white: $white,
	error: $error,
	warning: $warning,
	success: $success,
	disabled: $disabled,
	bodycolor: $bodycolor,
	headingcolor: $headingcolor
) !default;


// Color Helpers
//// Links
a {
	color: $primary;
    text-decoration: none;
    &.link-secondary {color: $secondary;}
	a:link a:visited {color: inherit;}
}
//// Undo link styles for placeholder links/named anchors (without href)
a:not([href]):not([tabindex]) {
	color: inherit;
	text-decoration: none;
	@media (hover: hover) {
		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}
	&:focus {
		outline: 0;
	}

}
//// Text & BG Overides
//// E.g Font Color: fc-primary or background .bg-black
@each $name, $color in $colors {
	.fc-#{$name} {
		color: #{$color}!important;
	}
	.bg-#{$name} {
		background-color: #{$color}!important;
	}
	.border-#{$name} {
		border: 1px solid #{$color};
	}
	.icon-#{$name} {
		stroke: #{$color};
		svg {stroke: #{$color};}
	}
}


// Hover States
@media (hover: hover) {
	a {
		-webkit-transition: all .2s ease;
		-moz-transition: all .2s ease;
		transition: all .2s ease;
		&.link-secondary:hover {color: lighten($secondary, 15%);}
	}
}
