/////////////////////////////////////////////////////////
// Iconography                                         //
/////////////////////////////////////////////////////////

// Setup
$iconsizes: 16,20,32,40,44,50,64,70,80,100,128,150!default;


// Icon Defaults
.icon {
	display: inline-block;
}
.icon-background {
	background-repeat: no-repeat;
	background-position: center center;
}

// Rotate modifiers
.icon-rotate-90 {
	-ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.icon-rotate-180 {
	-ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.icon-rotate-270 {
	-ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

// Generate Icon sizes
@each $iconsize in $iconsizes {
	.icon-#{$iconsize} {
		width: #{$iconsize}px;
		height: #{$iconsize}px;
		background-size: #{$iconsize}px #{$iconsize}px;
	} 
}