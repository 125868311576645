.blog-content {
	p {
		margin-bottom: 40px;
	}
	a {
		color: $headingcolor;
		border-bottom: 2px solid $primary;
		padding-bottom: 2px;
	}

	h3 {
		font-size: 32px;
		padding-bottom: 20px;
	}

	.steps {
		position: relative;
		margin-bottom: 60px;
		.step {
			position: relative;
			font-weight: bold;
			padding-left: 40px;
			p {
				margin-bottom: 30px;
			}

			&:before {
				position: absolute;
				content: '';
				width: 7px;
				height: 80%;
				background: $primary;
				left: 0;
				top: 10%;
			}
			&:after {
				content: '';
				position: absolute;
				left: 7px;
				top: 50%;
				margin-top: -8px;
				border-left: 8px solid $primary;
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;

			}
		}
	}

	img {
		width: 100%;
		margin-bottom: 50px;
	}

	.lists {
		position: relative;
		&.question {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 40px;
			li {
				width: 50%;
				padding-right: 30px;
				&:before {
					background: url('assets/img/double/category/icon-ask_2x.png') center center no-repeat;
					border-radius: 50%;;
					background-size: 32px  32px;;
				}
			}
		}
		&.half {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 50%;
				padding-right: 30px;
			}
		}
		li {
			position: relative;
			font-size: 16px;
			line-height: 28px;
			margin-bottom: 30px;
			padding-left: 60px;
			display: flex;
			align-items: center;

			@include media-breakpoint-up(xxl) {
				font-size: 20px;
				line-height: 36px;
			}

			&:before {
				position: absolute;
				left: 0;
				content: '';
				width: 32px;
				height: 33px;
				background: url('assets/img/regular/arrow.png') center center no-repeat;
				background-size: 32px 33px;

			}
		}
	}
}

.image-block {
	max-height: 170px;
	width: auto !important;
}
