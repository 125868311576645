$title-padding: 40px;
$title-text-padding: 70px;
$title-line-width: 186px;
$title-line-height: 50px;

.title {
	position: relative;
	padding-bottom: $title-padding;

	&.center {
		text-align: center;
		h2 {
			&:before {
				left: 50%;
				margin-left: -(($title-line-width - 56px) / 2);
				@include media-breakpoint-up(xxl) {

					margin-left: -($title-line-width / 2);
				}
			}
		}
	}

	&.flip-line {
		h2 {
			&:before {
				transform: scaleX(-1);
			}
		}
	}

	h2 {
		position: relative;
		padding-bottom: $title-text-padding - 30px;
		@include media-breakpoint-up(xxl) {
			padding-bottom: $title-text-padding;

		}

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			background: url('assets/img/regular/title-line.png') center center no-repeat;
				background-size: ($title-line-width - 56px) ($title-line-height - 15px);
				width: $title-line-width - 56px;
				height: $title-line-height - 15px;


			@include media-breakpoint-up(xxl) {
				background-size: $title-line-width $title-line-height;
				width: $title-line-width;
				height: $title-line-height;

				padding-bottom: $title-text-padding;
			}
		}
	}

	p {
		font-size: 18px;
		font-weight: 600;
		padding: 20px 0px 0;
		@include media-breakpoint-up(xxl) {
			font-size: 22px;
			padding: 20px 40px 0;

		}

		&.left-sub {
			padding: 20px 0 0;
		}
	}
}

.sub {
	font-size: 18px;
	font-weight: 600;
	padding: 0px 0px 0;
	@include media-breakpoint-up(xxl) {
		font-size: 22px;
	}
}


.banner-title {
	position: relative;
	padding-bottom: $title-text-padding - 20px;
	padding-top: 0 !important;
	@include media-breakpoint-up(xxl) {
		padding-bottom: $title-text-padding + 10px;

	}
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		background: url('assets/img/regular/title-line.png') center center no-repeat;
			background-size: ($title-line-width - 56px) ($title-line-height - 15px);
			width: $title-line-width - 56px;
			height: $title-line-height - 15px;

			left: 50%;
			margin-left: -(($title-line-width - 56px) / 2);
			@include media-breakpoint-up(xxl) {

				margin-left: -($title-line-width / 2);
			}


		@include media-breakpoint-up(xxl) {
			background-size: $title-line-width $title-line-height;
			width: $title-line-width;
			height: $title-line-height;

			padding-bottom: $title-text-padding;
		}
	}
}
