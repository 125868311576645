/////////////////////////////////////////////////////////
// Brand                                               //
/////////////////////////////////////////////////////////

// Setup

.logo {
	display: block;
	@include text-hide();
	background: url(assets/img/brand/logo/logo-_chatbot_white.png) no-repeat center center;
	background-size: 231px 35px;
	width: 231px;
	height: 35px;
	@include media-breakpoint-down(sm) {
		background-size: 132px 25px;
		width: 132px;
		height: 25px;

	}
	@include media-breakpoint-up(xxl) {
		background-size: 297px 45px;
		width: 297px;
		height: 45px;
	}
	&.center {
		margin:0 auto;
		@include media-breakpoint-up(md) {
			margin:0;
		}
	}
	&.secondary {
		background: url(assets/img/brand/logo/logo-_chatbot_blue.png) no-repeat center center;
		background-size: 297px 45px;
	}
}
