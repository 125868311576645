$seciton-padding: 130px 0;
$seciton-padding-mobile: 60px 0;
section {
	position: relative;
	padding: $seciton-padding-mobile;
	background-size: cover!important;
	&.bg-grey {
		background: $line;
	}
	&.small {
		padding: 80px 0;
	}
	@include media-breakpoint-up(xxl) {
		padding: $seciton-padding

	}
}

.large-top-padding {
	section, .featured-in {
			padding-top: 200px !important;
	}
}
