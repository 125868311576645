.team {
	justify-content: center;
	li {
		padding: 0 5px;
		@include media-breakpoint-up(md) {
			padding: 0 20px;
		}
	}
	img {
		width: 70px;
		@include media-breakpoint-up(md) {
			width: 100px;
		}
	}
	@include media-breakpoint-up(xxl) {
		justify-content: space-around;
		img {
			width: auto;
		}

	}
}
footer {
	position: relative;

	.footer-link {
		padding: 50px 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include media-breakpoint-up(lg) {
			padding: 70px 0;
		}

		ul {
			flex: 0 0 100%;
			margin-bottom: 20px;
			@include media-breakpoint-up(md) {
				flex: 0 0 25%;
				margin-bottom: 0;

			}

			a {
				font-family: $bfont;
				font-size: 16px;
				line-height: 32px;
				display: block;
				color: $headingcolor;
				@include media-breakpoint-up(xxl) {
					font-size: 20px;
					line-height: 36px;
				}

				&:hover {
					color: $primary;
				}
			}
		}
	}
	.copyright {
		padding: 30px 0;
		border-top: 1px solid #7d919c;
	}
}
