/////////////////////////////////////////////////////////
// Images                                              //
/////////////////////////////////////////////////////////

// Setup
$img-border-radius: 6px!default;
$img-thumb-pad: 5px!default;
$img-thumb-border: 1px solid $line!default;


// Image types
img {
	&.responsive {
		width:100%;
		height:auto;
		max-width: 100%;
	}
	&.rounded {
		@include border-radius($img-border-radius);
	}
	&.circle {
		@include border-radius(100%);
	}
	
	&.thumbnail {
		padding:$img-thumb-pad;
		border:$img-thumb-border;
	}
}

// Lazy images component
figure.image {
	position:relative;
	.preload {
		position:absolute;
		z-index:-1;
		width:100%;
	}
	.lozad {
		opacity:0;
		transition: opacity 500ms ease 100ms;
		&[data-loaded] {
			opacity: 1;
		}
	}
}

// Background images
.bg-image {
	background-repeat: no-repeat;
	background-size: 100% auto;
	&.cover {
		background-size:cover;
	}
	&.contain {
		background-size:contain;
	}
}

// Aspect ratio
.aspect {
	&::before {
		display: block;
		content: "";
	}
	&.aspect-21by9 {
		&::before {
			padding-top: percentage(9 / 21);
		}
	}
	&.aspect-16by9 {
		&::before {
			padding-top: percentage(9 / 16);
		}
	}
	&.aspect-4by3 {
		&::before {
			padding-top: percentage(3 / 4);
		}
	}
	&.aspect-1by1 {
		&::before {
			padding-top: percentage(1 / 1);
		}
	} 
}