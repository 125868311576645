$icon-content-height: 200px;
$icon-first-bg: #3b7eff;
.feature-card {
	position: relative;
	@include transition(all .3s ease);
	border-radius: 0 0 8px 8px;
	margin-bottom: 25px;

	@include media-breakpoint-up(lg) {
		margin-bottom: 0;
		height: 100%;
	}

	&.blog {
		box-shadow: 0 0 49px 0 rgba(0,0, 0, .08);
		.icon-content {
			margin-bottom: 30px;
		}
		.details {
			h4 {
				padding-bottom: 20px;
			}
		}
	}

	&:hover {
		box-shadow: 0 0 49px 0 rgba(0,0, 0, .08);
	}

	.icon-content {
		margin-bottom: 20px;
		img {width: 100%;}
	}

	.details {
		padding: 0 20px 30px;

		p {
			word-break: break-all;
			padding-bottom: 20px;
		}
	}
}
