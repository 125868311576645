html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

*, *::before, *::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0; }

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin: 0;
  font-style: normal;
  line-height: inherit; }

ol, ul, dl, ol ol, ul ul, ol ul, ul ol, dd, blockquote {
  margin: 0; }

dfn {
  font-style: italic; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

pre, code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin: 0; }

button:focus {
  outline: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }
  input[type="radio"]:disabled,
  input[type="checkbox"]:disabled {
    cursor: default; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*, *::before, *::after {
  box-sizing: inherit; }

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 400px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 576px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 768px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1200px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .container {
      padding-left: 0px;
      padding-right: 0px; } }

.row {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 400px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 576px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 768px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 992px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 1200px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col, .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl {
  position: relative;
  width: 100%;
  min-height: 1px; }
  @media (min-width: 400px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 576px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 768px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1200px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl {
      padding-right: 15px;
      padding-left: 15px; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.pull-0 {
  right: auto; }

.pull-1 {
  right: 8.33333%; }

.pull-2 {
  right: 16.66667%; }

.pull-3 {
  right: 25%; }

.pull-4 {
  right: 33.33333%; }

.pull-5 {
  right: 41.66667%; }

.pull-6 {
  right: 50%; }

.pull-7 {
  right: 58.33333%; }

.pull-8 {
  right: 66.66667%; }

.pull-9 {
  right: 75%; }

.pull-10 {
  right: 83.33333%; }

.pull-11 {
  right: 91.66667%; }

.pull-12 {
  right: 100%; }

.push-0 {
  left: auto; }

.push-1 {
  left: 8.33333%; }

.push-2 {
  left: 16.66667%; }

.push-3 {
  left: 25%; }

.push-4 {
  left: 33.33333%; }

.push-5 {
  left: 41.66667%; }

.push-6 {
  left: 50%; }

.push-7 {
  left: 58.33333%; }

.push-8 {
  left: 66.66667%; }

.push-9 {
  left: 75%; }

.push-10 {
  left: 83.33333%; }

.push-11 {
  left: 91.66667%; }

.push-12 {
  left: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 400px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-xs-0 {
    right: auto; }
  .pull-xs-1 {
    right: 8.33333%; }
  .pull-xs-2 {
    right: 16.66667%; }
  .pull-xs-3 {
    right: 25%; }
  .pull-xs-4 {
    right: 33.33333%; }
  .pull-xs-5 {
    right: 41.66667%; }
  .pull-xs-6 {
    right: 50%; }
  .pull-xs-7 {
    right: 58.33333%; }
  .pull-xs-8 {
    right: 66.66667%; }
  .pull-xs-9 {
    right: 75%; }
  .pull-xs-10 {
    right: 83.33333%; }
  .pull-xs-11 {
    right: 91.66667%; }
  .pull-xs-12 {
    right: 100%; }
  .push-xs-0 {
    left: auto; }
  .push-xs-1 {
    left: 8.33333%; }
  .push-xs-2 {
    left: 16.66667%; }
  .push-xs-3 {
    left: 25%; }
  .push-xs-4 {
    left: 33.33333%; }
  .push-xs-5 {
    left: 41.66667%; }
  .push-xs-6 {
    left: 50%; }
  .push-xs-7 {
    left: 58.33333%; }
  .push-xs-8 {
    left: 66.66667%; }
  .push-xs-9 {
    left: 75%; }
  .push-xs-10 {
    left: 83.33333%; }
  .push-xs-11 {
    left: 91.66667%; }
  .push-xs-12 {
    left: 100%; }
  .offset-xs-0 {
    margin-left: 0%; }
  .offset-xs-1 {
    margin-left: 8.33333%; }
  .offset-xs-2 {
    margin-left: 16.66667%; }
  .offset-xs-3 {
    margin-left: 25%; }
  .offset-xs-4 {
    margin-left: 33.33333%; }
  .offset-xs-5 {
    margin-left: 41.66667%; }
  .offset-xs-6 {
    margin-left: 50%; }
  .offset-xs-7 {
    margin-left: 58.33333%; }
  .offset-xs-8 {
    margin-left: 66.66667%; }
  .offset-xs-9 {
    margin-left: 75%; }
  .offset-xs-10 {
    margin-left: 83.33333%; }
  .offset-xs-11 {
    margin-left: 91.66667%; } }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-sm-0 {
    right: auto; }
  .pull-sm-1 {
    right: 8.33333%; }
  .pull-sm-2 {
    right: 16.66667%; }
  .pull-sm-3 {
    right: 25%; }
  .pull-sm-4 {
    right: 33.33333%; }
  .pull-sm-5 {
    right: 41.66667%; }
  .pull-sm-6 {
    right: 50%; }
  .pull-sm-7 {
    right: 58.33333%; }
  .pull-sm-8 {
    right: 66.66667%; }
  .pull-sm-9 {
    right: 75%; }
  .pull-sm-10 {
    right: 83.33333%; }
  .pull-sm-11 {
    right: 91.66667%; }
  .pull-sm-12 {
    right: 100%; }
  .push-sm-0 {
    left: auto; }
  .push-sm-1 {
    left: 8.33333%; }
  .push-sm-2 {
    left: 16.66667%; }
  .push-sm-3 {
    left: 25%; }
  .push-sm-4 {
    left: 33.33333%; }
  .push-sm-5 {
    left: 41.66667%; }
  .push-sm-6 {
    left: 50%; }
  .push-sm-7 {
    left: 58.33333%; }
  .push-sm-8 {
    left: 66.66667%; }
  .push-sm-9 {
    left: 75%; }
  .push-sm-10 {
    left: 83.33333%; }
  .push-sm-11 {
    left: 91.66667%; }
  .push-sm-12 {
    left: 100%; }
  .offset-sm-0 {
    margin-left: 0%; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-md-0 {
    right: auto; }
  .pull-md-1 {
    right: 8.33333%; }
  .pull-md-2 {
    right: 16.66667%; }
  .pull-md-3 {
    right: 25%; }
  .pull-md-4 {
    right: 33.33333%; }
  .pull-md-5 {
    right: 41.66667%; }
  .pull-md-6 {
    right: 50%; }
  .pull-md-7 {
    right: 58.33333%; }
  .pull-md-8 {
    right: 66.66667%; }
  .pull-md-9 {
    right: 75%; }
  .pull-md-10 {
    right: 83.33333%; }
  .pull-md-11 {
    right: 91.66667%; }
  .pull-md-12 {
    right: 100%; }
  .push-md-0 {
    left: auto; }
  .push-md-1 {
    left: 8.33333%; }
  .push-md-2 {
    left: 16.66667%; }
  .push-md-3 {
    left: 25%; }
  .push-md-4 {
    left: 33.33333%; }
  .push-md-5 {
    left: 41.66667%; }
  .push-md-6 {
    left: 50%; }
  .push-md-7 {
    left: 58.33333%; }
  .push-md-8 {
    left: 66.66667%; }
  .push-md-9 {
    left: 75%; }
  .push-md-10 {
    left: 83.33333%; }
  .push-md-11 {
    left: 91.66667%; }
  .push-md-12 {
    left: 100%; }
  .offset-md-0 {
    margin-left: 0%; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-lg-0 {
    right: auto; }
  .pull-lg-1 {
    right: 8.33333%; }
  .pull-lg-2 {
    right: 16.66667%; }
  .pull-lg-3 {
    right: 25%; }
  .pull-lg-4 {
    right: 33.33333%; }
  .pull-lg-5 {
    right: 41.66667%; }
  .pull-lg-6 {
    right: 50%; }
  .pull-lg-7 {
    right: 58.33333%; }
  .pull-lg-8 {
    right: 66.66667%; }
  .pull-lg-9 {
    right: 75%; }
  .pull-lg-10 {
    right: 83.33333%; }
  .pull-lg-11 {
    right: 91.66667%; }
  .pull-lg-12 {
    right: 100%; }
  .push-lg-0 {
    left: auto; }
  .push-lg-1 {
    left: 8.33333%; }
  .push-lg-2 {
    left: 16.66667%; }
  .push-lg-3 {
    left: 25%; }
  .push-lg-4 {
    left: 33.33333%; }
  .push-lg-5 {
    left: 41.66667%; }
  .push-lg-6 {
    left: 50%; }
  .push-lg-7 {
    left: 58.33333%; }
  .push-lg-8 {
    left: 66.66667%; }
  .push-lg-9 {
    left: 75%; }
  .push-lg-10 {
    left: 83.33333%; }
  .push-lg-11 {
    left: 91.66667%; }
  .push-lg-12 {
    left: 100%; }
  .offset-lg-0 {
    margin-left: 0%; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-xl-0 {
    right: auto; }
  .pull-xl-1 {
    right: 8.33333%; }
  .pull-xl-2 {
    right: 16.66667%; }
  .pull-xl-3 {
    right: 25%; }
  .pull-xl-4 {
    right: 33.33333%; }
  .pull-xl-5 {
    right: 41.66667%; }
  .pull-xl-6 {
    right: 50%; }
  .pull-xl-7 {
    right: 58.33333%; }
  .pull-xl-8 {
    right: 66.66667%; }
  .pull-xl-9 {
    right: 75%; }
  .pull-xl-10 {
    right: 83.33333%; }
  .pull-xl-11 {
    right: 91.66667%; }
  .pull-xl-12 {
    right: 100%; }
  .push-xl-0 {
    left: auto; }
  .push-xl-1 {
    left: 8.33333%; }
  .push-xl-2 {
    left: 16.66667%; }
  .push-xl-3 {
    left: 25%; }
  .push-xl-4 {
    left: 33.33333%; }
  .push-xl-5 {
    left: 41.66667%; }
  .push-xl-6 {
    left: 50%; }
  .push-xl-7 {
    left: 58.33333%; }
  .push-xl-8 {
    left: 66.66667%; }
  .push-xl-9 {
    left: 75%; }
  .push-xl-10 {
    left: 83.33333%; }
  .push-xl-11 {
    left: 91.66667%; }
  .push-xl-12 {
    left: 100%; }
  .offset-xl-0 {
    margin-left: 0%; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1500px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-xxl-0 {
    right: auto; }
  .pull-xxl-1 {
    right: 8.33333%; }
  .pull-xxl-2 {
    right: 16.66667%; }
  .pull-xxl-3 {
    right: 25%; }
  .pull-xxl-4 {
    right: 33.33333%; }
  .pull-xxl-5 {
    right: 41.66667%; }
  .pull-xxl-6 {
    right: 50%; }
  .pull-xxl-7 {
    right: 58.33333%; }
  .pull-xxl-8 {
    right: 66.66667%; }
  .pull-xxl-9 {
    right: 75%; }
  .pull-xxl-10 {
    right: 83.33333%; }
  .pull-xxl-11 {
    right: 91.66667%; }
  .pull-xxl-12 {
    right: 100%; }
  .push-xxl-0 {
    left: auto; }
  .push-xxl-1 {
    left: 8.33333%; }
  .push-xxl-2 {
    left: 16.66667%; }
  .push-xxl-3 {
    left: 25%; }
  .push-xxl-4 {
    left: 33.33333%; }
  .push-xxl-5 {
    left: 41.66667%; }
  .push-xxl-6 {
    left: 50%; }
  .push-xxl-7 {
    left: 58.33333%; }
  .push-xxl-8 {
    left: 66.66667%; }
  .push-xxl-9 {
    left: 75%; }
  .push-xxl-10 {
    left: 83.33333%; }
  .push-xxl-11 {
    left: 91.66667%; }
  .push-xxl-12 {
    left: 100%; }
  .offset-xxl-0 {
    margin-left: 0%; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.clearfix {
  *zoom: 1; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.order-first {
  order: -1; }

.order-last {
  order: 1; }

.order-0 {
  order: 0; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.p-0 {
  padding: 0px; }

.py-0 {
  padding-left: 0px;
  padding-right: 0px; }

.px-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.pt-0 {
  padding-top: 0px; }

.pb-0 {
  padding-bottom: 0px; }

.pl-0 {
  padding-left: 0px; }

.pr-0 {
  padding-right: 0px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-0 {
  margin: 0px; }

.my-0 {
  margin-left: 0px;
  margin-right: 0px; }

.mx-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.mt-0 {
  margin-top: 0px; }

.mb-0 {
  margin-bottom: 0px; }

.ml-0 {
  margin-left: 0px; }

.mr-0 {
  margin-right: 0px; }

.p-5 {
  padding: 5px; }

.py-5 {
  padding-left: 5px;
  padding-right: 5px; }

.px-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.pt-5 {
  padding-top: 5px; }

.pb-5 {
  padding-bottom: 5px; }

.pl-5 {
  padding-left: 5px; }

.pr-5 {
  padding-right: 5px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-5 {
  margin: 5px; }

.my-5 {
  margin-left: 5px;
  margin-right: 5px; }

.mx-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.mt-5 {
  margin-top: 5px; }

.mb-5 {
  margin-bottom: 5px; }

.ml-5 {
  margin-left: 5px; }

.mr-5 {
  margin-right: 5px; }

.p-10 {
  padding: 10px; }

.py-10 {
  padding-left: 10px;
  padding-right: 10px; }

.px-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.pt-10 {
  padding-top: 10px; }

.pb-10 {
  padding-bottom: 10px; }

.pl-10 {
  padding-left: 10px; }

.pr-10 {
  padding-right: 10px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-10 {
  margin: 10px; }

.my-10 {
  margin-left: 10px;
  margin-right: 10px; }

.mx-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.mt-10 {
  margin-top: 10px; }

.mb-10 {
  margin-bottom: 10px; }

.ml-10 {
  margin-left: 10px; }

.mr-10 {
  margin-right: 10px; }

.p-15 {
  padding: 15px; }

.py-15 {
  padding-left: 15px;
  padding-right: 15px; }

.px-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.pt-15 {
  padding-top: 15px; }

.pb-15 {
  padding-bottom: 15px; }

.pl-15 {
  padding-left: 15px; }

.pr-15 {
  padding-right: 15px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-15 {
  margin: 15px; }

.my-15 {
  margin-left: 15px;
  margin-right: 15px; }

.mx-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.mt-15 {
  margin-top: 15px; }

.mb-15 {
  margin-bottom: 15px; }

.ml-15 {
  margin-left: 15px; }

.mr-15 {
  margin-right: 15px; }

.p-20 {
  padding: 20px; }

.py-20 {
  padding-left: 20px;
  padding-right: 20px; }

.px-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.pt-20 {
  padding-top: 20px; }

.pb-20 {
  padding-bottom: 20px; }

.pl-20 {
  padding-left: 20px; }

.pr-20 {
  padding-right: 20px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-20 {
  margin: 20px; }

.my-20 {
  margin-left: 20px;
  margin-right: 20px; }

.mx-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.mt-20 {
  margin-top: 20px; }

.mb-20 {
  margin-bottom: 20px; }

.ml-20 {
  margin-left: 20px; }

.mr-20 {
  margin-right: 20px; }

.p-30 {
  padding: 30px; }

.py-30 {
  padding-left: 30px;
  padding-right: 30px; }

.px-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.pt-30 {
  padding-top: 30px; }

.pb-30 {
  padding-bottom: 30px; }

.pl-30 {
  padding-left: 30px; }

.pr-30 {
  padding-right: 30px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-30 {
  margin: 30px; }

.my-30 {
  margin-left: 30px;
  margin-right: 30px; }

.mx-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.mt-30 {
  margin-top: 30px; }

.mb-30 {
  margin-bottom: 30px; }

.ml-30 {
  margin-left: 30px; }

.mr-30 {
  margin-right: 30px; }

.p-40 {
  padding: 40px; }

.py-40 {
  padding-left: 40px;
  padding-right: 40px; }

.px-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.pt-40 {
  padding-top: 40px; }

.pb-40 {
  padding-bottom: 40px; }

.pl-40 {
  padding-left: 40px; }

.pr-40 {
  padding-right: 40px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-40 {
  margin: 40px; }

.my-40 {
  margin-left: 40px;
  margin-right: 40px; }

.mx-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.mt-40 {
  margin-top: 40px; }

.mb-40 {
  margin-bottom: 40px; }

.ml-40 {
  margin-left: 40px; }

.mr-40 {
  margin-right: 40px; }

.p-50 {
  padding: 50px; }

.py-50 {
  padding-left: 50px;
  padding-right: 50px; }

.px-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.pt-50 {
  padding-top: 50px; }

.pb-50 {
  padding-bottom: 50px; }

.pl-50 {
  padding-left: 50px; }

.pr-50 {
  padding-right: 50px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-50 {
  margin: 50px; }

.my-50 {
  margin-left: 50px;
  margin-right: 50px; }

.mx-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.mt-50 {
  margin-top: 50px; }

.mb-50 {
  margin-bottom: 50px; }

.ml-50 {
  margin-left: 50px; }

.mr-50 {
  margin-right: 50px; }

.p-60 {
  padding: 60px; }

.py-60 {
  padding-left: 60px;
  padding-right: 60px; }

.px-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.pt-60 {
  padding-top: 60px; }

.pb-60 {
  padding-bottom: 60px; }

.pl-60 {
  padding-left: 60px; }

.pr-60 {
  padding-right: 60px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-60 {
  margin: 60px; }

.my-60 {
  margin-left: 60px;
  margin-right: 60px; }

.mx-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.mt-60 {
  margin-top: 60px; }

.mb-60 {
  margin-bottom: 60px; }

.ml-60 {
  margin-left: 60px; }

.mr-60 {
  margin-right: 60px; }

.p-70 {
  padding: 70px; }

.py-70 {
  padding-left: 70px;
  padding-right: 70px; }

.px-70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.pt-70 {
  padding-top: 70px; }

.pb-70 {
  padding-bottom: 70px; }

.pl-70 {
  padding-left: 70px; }

.pr-70 {
  padding-right: 70px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-70 {
  margin: 70px; }

.my-70 {
  margin-left: 70px;
  margin-right: 70px; }

.mx-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.mt-70 {
  margin-top: 70px; }

.mb-70 {
  margin-bottom: 70px; }

.ml-70 {
  margin-left: 70px; }

.mr-70 {
  margin-right: 70px; }

.p-80 {
  padding: 80px; }

.py-80 {
  padding-left: 80px;
  padding-right: 80px; }

.px-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.pt-80 {
  padding-top: 80px; }

.pb-80 {
  padding-bottom: 80px; }

.pl-80 {
  padding-left: 80px; }

.pr-80 {
  padding-right: 80px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-80 {
  margin: 80px; }

.my-80 {
  margin-left: 80px;
  margin-right: 80px; }

.mx-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.mt-80 {
  margin-top: 80px; }

.mb-80 {
  margin-bottom: 80px; }

.ml-80 {
  margin-left: 80px; }

.mr-80 {
  margin-right: 80px; }

.p-90 {
  padding: 90px; }

.py-90 {
  padding-left: 90px;
  padding-right: 90px; }

.px-90 {
  padding-top: 90px;
  padding-bottom: 90px; }

.pt-90 {
  padding-top: 90px; }

.pb-90 {
  padding-bottom: 90px; }

.pl-90 {
  padding-left: 90px; }

.pr-90 {
  padding-right: 90px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-90 {
  margin: 90px; }

.my-90 {
  margin-left: 90px;
  margin-right: 90px; }

.mx-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.mt-90 {
  margin-top: 90px; }

.mb-90 {
  margin-bottom: 90px; }

.ml-90 {
  margin-left: 90px; }

.mr-90 {
  margin-right: 90px; }

.p-100 {
  padding: 100px; }

.py-100 {
  padding-left: 100px;
  padding-right: 100px; }

.px-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.pt-100 {
  padding-top: 100px; }

.pb-100 {
  padding-bottom: 100px; }

.pl-100 {
  padding-left: 100px; }

.pr-100 {
  padding-right: 100px; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.m-100 {
  margin: 100px; }

.my-100 {
  margin-left: 100px;
  margin-right: 100px; }

.mx-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.mt-100 {
  margin-top: 100px; }

.mb-100 {
  margin-bottom: 100px; }

.ml-100 {
  margin-left: 100px; }

.mr-100 {
  margin-right: 100px; }

.zindex--1 {
  z-index: -1; }

.zindex-0 {
  z-index: 0; }

.zindex-10 {
  z-index: 10; }

.zindex-20 {
  z-index: 20; }

.zindex-30 {
  z-index: 30; }

.zindex-40 {
  z-index: 40; }

.zindex-50 {
  z-index: 50; }

.zindex-60 {
  z-index: 60; }

.zindex-70 {
  z-index: 70; }

.zindex-80 {
  z-index: 80; }

.zindex-90 {
  z-index: 90; }

.zindex-100 {
  z-index: 100; }

@media (min-width: 400px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; }
  .order-xs-first {
    order: -1; }
  .order-xs-last {
    order: 1; }
  .order-xs-0 {
    order: 0; }
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; }
  .p-xs-0 {
    padding: 0px; }
  .py-xs-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .px-xs-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .pt-xs-0 {
    padding-top: 0px; }
  .pb-xs-0 {
    padding-bottom: 0px; }
  .pl-xs-0 {
    padding-left: 0px; }
  .pr-xs-0 {
    padding-right: 0px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-0 {
    margin: 0px; }
  .my-xs-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .mx-xs-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mt-xs-0 {
    margin-top: 0px; }
  .mb-xs-0 {
    margin-bottom: 0px; }
  .ml-xs-0 {
    margin-left: 0px; }
  .mr-xs-0 {
    margin-right: 0px; }
  .p-xs-5 {
    padding: 5px; }
  .py-xs-5 {
    padding-left: 5px;
    padding-right: 5px; }
  .px-xs-5 {
    padding-top: 5px;
    padding-bottom: 5px; }
  .pt-xs-5 {
    padding-top: 5px; }
  .pb-xs-5 {
    padding-bottom: 5px; }
  .pl-xs-5 {
    padding-left: 5px; }
  .pr-xs-5 {
    padding-right: 5px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-5 {
    margin: 5px; }
  .my-xs-5 {
    margin-left: 5px;
    margin-right: 5px; }
  .mx-xs-5 {
    margin-top: 5px;
    margin-bottom: 5px; }
  .mt-xs-5 {
    margin-top: 5px; }
  .mb-xs-5 {
    margin-bottom: 5px; }
  .ml-xs-5 {
    margin-left: 5px; }
  .mr-xs-5 {
    margin-right: 5px; }
  .p-xs-10 {
    padding: 10px; }
  .py-xs-10 {
    padding-left: 10px;
    padding-right: 10px; }
  .px-xs-10 {
    padding-top: 10px;
    padding-bottom: 10px; }
  .pt-xs-10 {
    padding-top: 10px; }
  .pb-xs-10 {
    padding-bottom: 10px; }
  .pl-xs-10 {
    padding-left: 10px; }
  .pr-xs-10 {
    padding-right: 10px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-10 {
    margin: 10px; }
  .my-xs-10 {
    margin-left: 10px;
    margin-right: 10px; }
  .mx-xs-10 {
    margin-top: 10px;
    margin-bottom: 10px; }
  .mt-xs-10 {
    margin-top: 10px; }
  .mb-xs-10 {
    margin-bottom: 10px; }
  .ml-xs-10 {
    margin-left: 10px; }
  .mr-xs-10 {
    margin-right: 10px; }
  .p-xs-15 {
    padding: 15px; }
  .py-xs-15 {
    padding-left: 15px;
    padding-right: 15px; }
  .px-xs-15 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .pt-xs-15 {
    padding-top: 15px; }
  .pb-xs-15 {
    padding-bottom: 15px; }
  .pl-xs-15 {
    padding-left: 15px; }
  .pr-xs-15 {
    padding-right: 15px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-15 {
    margin: 15px; }
  .my-xs-15 {
    margin-left: 15px;
    margin-right: 15px; }
  .mx-xs-15 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mt-xs-15 {
    margin-top: 15px; }
  .mb-xs-15 {
    margin-bottom: 15px; }
  .ml-xs-15 {
    margin-left: 15px; }
  .mr-xs-15 {
    margin-right: 15px; }
  .p-xs-20 {
    padding: 20px; }
  .py-xs-20 {
    padding-left: 20px;
    padding-right: 20px; }
  .px-xs-20 {
    padding-top: 20px;
    padding-bottom: 20px; }
  .pt-xs-20 {
    padding-top: 20px; }
  .pb-xs-20 {
    padding-bottom: 20px; }
  .pl-xs-20 {
    padding-left: 20px; }
  .pr-xs-20 {
    padding-right: 20px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-20 {
    margin: 20px; }
  .my-xs-20 {
    margin-left: 20px;
    margin-right: 20px; }
  .mx-xs-20 {
    margin-top: 20px;
    margin-bottom: 20px; }
  .mt-xs-20 {
    margin-top: 20px; }
  .mb-xs-20 {
    margin-bottom: 20px; }
  .ml-xs-20 {
    margin-left: 20px; }
  .mr-xs-20 {
    margin-right: 20px; }
  .p-xs-30 {
    padding: 30px; }
  .py-xs-30 {
    padding-left: 30px;
    padding-right: 30px; }
  .px-xs-30 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .pt-xs-30 {
    padding-top: 30px; }
  .pb-xs-30 {
    padding-bottom: 30px; }
  .pl-xs-30 {
    padding-left: 30px; }
  .pr-xs-30 {
    padding-right: 30px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-30 {
    margin: 30px; }
  .my-xs-30 {
    margin-left: 30px;
    margin-right: 30px; }
  .mx-xs-30 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mt-xs-30 {
    margin-top: 30px; }
  .mb-xs-30 {
    margin-bottom: 30px; }
  .ml-xs-30 {
    margin-left: 30px; }
  .mr-xs-30 {
    margin-right: 30px; }
  .p-xs-40 {
    padding: 40px; }
  .py-xs-40 {
    padding-left: 40px;
    padding-right: 40px; }
  .px-xs-40 {
    padding-top: 40px;
    padding-bottom: 40px; }
  .pt-xs-40 {
    padding-top: 40px; }
  .pb-xs-40 {
    padding-bottom: 40px; }
  .pl-xs-40 {
    padding-left: 40px; }
  .pr-xs-40 {
    padding-right: 40px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-40 {
    margin: 40px; }
  .my-xs-40 {
    margin-left: 40px;
    margin-right: 40px; }
  .mx-xs-40 {
    margin-top: 40px;
    margin-bottom: 40px; }
  .mt-xs-40 {
    margin-top: 40px; }
  .mb-xs-40 {
    margin-bottom: 40px; }
  .ml-xs-40 {
    margin-left: 40px; }
  .mr-xs-40 {
    margin-right: 40px; }
  .p-xs-50 {
    padding: 50px; }
  .py-xs-50 {
    padding-left: 50px;
    padding-right: 50px; }
  .px-xs-50 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .pt-xs-50 {
    padding-top: 50px; }
  .pb-xs-50 {
    padding-bottom: 50px; }
  .pl-xs-50 {
    padding-left: 50px; }
  .pr-xs-50 {
    padding-right: 50px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-50 {
    margin: 50px; }
  .my-xs-50 {
    margin-left: 50px;
    margin-right: 50px; }
  .mx-xs-50 {
    margin-top: 50px;
    margin-bottom: 50px; }
  .mt-xs-50 {
    margin-top: 50px; }
  .mb-xs-50 {
    margin-bottom: 50px; }
  .ml-xs-50 {
    margin-left: 50px; }
  .mr-xs-50 {
    margin-right: 50px; }
  .p-xs-60 {
    padding: 60px; }
  .py-xs-60 {
    padding-left: 60px;
    padding-right: 60px; }
  .px-xs-60 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .pt-xs-60 {
    padding-top: 60px; }
  .pb-xs-60 {
    padding-bottom: 60px; }
  .pl-xs-60 {
    padding-left: 60px; }
  .pr-xs-60 {
    padding-right: 60px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-60 {
    margin: 60px; }
  .my-xs-60 {
    margin-left: 60px;
    margin-right: 60px; }
  .mx-xs-60 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mt-xs-60 {
    margin-top: 60px; }
  .mb-xs-60 {
    margin-bottom: 60px; }
  .ml-xs-60 {
    margin-left: 60px; }
  .mr-xs-60 {
    margin-right: 60px; }
  .p-xs-70 {
    padding: 70px; }
  .py-xs-70 {
    padding-left: 70px;
    padding-right: 70px; }
  .px-xs-70 {
    padding-top: 70px;
    padding-bottom: 70px; }
  .pt-xs-70 {
    padding-top: 70px; }
  .pb-xs-70 {
    padding-bottom: 70px; }
  .pl-xs-70 {
    padding-left: 70px; }
  .pr-xs-70 {
    padding-right: 70px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-70 {
    margin: 70px; }
  .my-xs-70 {
    margin-left: 70px;
    margin-right: 70px; }
  .mx-xs-70 {
    margin-top: 70px;
    margin-bottom: 70px; }
  .mt-xs-70 {
    margin-top: 70px; }
  .mb-xs-70 {
    margin-bottom: 70px; }
  .ml-xs-70 {
    margin-left: 70px; }
  .mr-xs-70 {
    margin-right: 70px; }
  .p-xs-80 {
    padding: 80px; }
  .py-xs-80 {
    padding-left: 80px;
    padding-right: 80px; }
  .px-xs-80 {
    padding-top: 80px;
    padding-bottom: 80px; }
  .pt-xs-80 {
    padding-top: 80px; }
  .pb-xs-80 {
    padding-bottom: 80px; }
  .pl-xs-80 {
    padding-left: 80px; }
  .pr-xs-80 {
    padding-right: 80px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-80 {
    margin: 80px; }
  .my-xs-80 {
    margin-left: 80px;
    margin-right: 80px; }
  .mx-xs-80 {
    margin-top: 80px;
    margin-bottom: 80px; }
  .mt-xs-80 {
    margin-top: 80px; }
  .mb-xs-80 {
    margin-bottom: 80px; }
  .ml-xs-80 {
    margin-left: 80px; }
  .mr-xs-80 {
    margin-right: 80px; }
  .p-xs-90 {
    padding: 90px; }
  .py-xs-90 {
    padding-left: 90px;
    padding-right: 90px; }
  .px-xs-90 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .pt-xs-90 {
    padding-top: 90px; }
  .pb-xs-90 {
    padding-bottom: 90px; }
  .pl-xs-90 {
    padding-left: 90px; }
  .pr-xs-90 {
    padding-right: 90px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-90 {
    margin: 90px; }
  .my-xs-90 {
    margin-left: 90px;
    margin-right: 90px; }
  .mx-xs-90 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mt-xs-90 {
    margin-top: 90px; }
  .mb-xs-90 {
    margin-bottom: 90px; }
  .ml-xs-90 {
    margin-left: 90px; }
  .mr-xs-90 {
    margin-right: 90px; }
  .p-xs-100 {
    padding: 100px; }
  .py-xs-100 {
    padding-left: 100px;
    padding-right: 100px; }
  .px-xs-100 {
    padding-top: 100px;
    padding-bottom: 100px; }
  .pt-xs-100 {
    padding-top: 100px; }
  .pb-xs-100 {
    padding-bottom: 100px; }
  .pl-xs-100 {
    padding-left: 100px; }
  .pr-xs-100 {
    padding-right: 100px; }
  .m-xs-auto {
    margin: auto; }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xs-auto {
    margin-top: auto; }
  .mb-xs-auto {
    margin-bottom: auto; }
  .ml-xs-auto {
    margin-left: auto; }
  .mr-xs-auto {
    margin-right: auto; }
  .m-xs-100 {
    margin: 100px; }
  .my-xs-100 {
    margin-left: 100px;
    margin-right: 100px; }
  .mx-xs-100 {
    margin-top: 100px;
    margin-bottom: 100px; }
  .mt-xs-100 {
    margin-top: 100px; }
  .mb-xs-100 {
    margin-bottom: 100px; }
  .ml-xs-100 {
    margin-left: 100px; }
  .mr-xs-100 {
    margin-right: 100px; }
  .zindex-xs--1 {
    z-index: -1; }
  .zindex-xs-0 {
    z-index: 0; }
  .zindex-xs-10 {
    z-index: 10; }
  .zindex-xs-20 {
    z-index: 20; }
  .zindex-xs-30 {
    z-index: 30; }
  .zindex-xs-40 {
    z-index: 40; }
  .zindex-xs-50 {
    z-index: 50; }
  .zindex-xs-60 {
    z-index: 60; }
  .zindex-xs-70 {
    z-index: 70; }
  .zindex-xs-80 {
    z-index: 80; }
  .zindex-xs-90 {
    z-index: 90; }
  .zindex-xs-100 {
    z-index: 100; } }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 1; }
  .order-sm-0 {
    order: 0; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .p-sm-0 {
    padding: 0px; }
  .py-sm-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .px-sm-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .pt-sm-0 {
    padding-top: 0px; }
  .pb-sm-0 {
    padding-bottom: 0px; }
  .pl-sm-0 {
    padding-left: 0px; }
  .pr-sm-0 {
    padding-right: 0px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-0 {
    margin: 0px; }
  .my-sm-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .mx-sm-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mt-sm-0 {
    margin-top: 0px; }
  .mb-sm-0 {
    margin-bottom: 0px; }
  .ml-sm-0 {
    margin-left: 0px; }
  .mr-sm-0 {
    margin-right: 0px; }
  .p-sm-5 {
    padding: 5px; }
  .py-sm-5 {
    padding-left: 5px;
    padding-right: 5px; }
  .px-sm-5 {
    padding-top: 5px;
    padding-bottom: 5px; }
  .pt-sm-5 {
    padding-top: 5px; }
  .pb-sm-5 {
    padding-bottom: 5px; }
  .pl-sm-5 {
    padding-left: 5px; }
  .pr-sm-5 {
    padding-right: 5px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-5 {
    margin: 5px; }
  .my-sm-5 {
    margin-left: 5px;
    margin-right: 5px; }
  .mx-sm-5 {
    margin-top: 5px;
    margin-bottom: 5px; }
  .mt-sm-5 {
    margin-top: 5px; }
  .mb-sm-5 {
    margin-bottom: 5px; }
  .ml-sm-5 {
    margin-left: 5px; }
  .mr-sm-5 {
    margin-right: 5px; }
  .p-sm-10 {
    padding: 10px; }
  .py-sm-10 {
    padding-left: 10px;
    padding-right: 10px; }
  .px-sm-10 {
    padding-top: 10px;
    padding-bottom: 10px; }
  .pt-sm-10 {
    padding-top: 10px; }
  .pb-sm-10 {
    padding-bottom: 10px; }
  .pl-sm-10 {
    padding-left: 10px; }
  .pr-sm-10 {
    padding-right: 10px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-10 {
    margin: 10px; }
  .my-sm-10 {
    margin-left: 10px;
    margin-right: 10px; }
  .mx-sm-10 {
    margin-top: 10px;
    margin-bottom: 10px; }
  .mt-sm-10 {
    margin-top: 10px; }
  .mb-sm-10 {
    margin-bottom: 10px; }
  .ml-sm-10 {
    margin-left: 10px; }
  .mr-sm-10 {
    margin-right: 10px; }
  .p-sm-15 {
    padding: 15px; }
  .py-sm-15 {
    padding-left: 15px;
    padding-right: 15px; }
  .px-sm-15 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .pt-sm-15 {
    padding-top: 15px; }
  .pb-sm-15 {
    padding-bottom: 15px; }
  .pl-sm-15 {
    padding-left: 15px; }
  .pr-sm-15 {
    padding-right: 15px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-15 {
    margin: 15px; }
  .my-sm-15 {
    margin-left: 15px;
    margin-right: 15px; }
  .mx-sm-15 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mt-sm-15 {
    margin-top: 15px; }
  .mb-sm-15 {
    margin-bottom: 15px; }
  .ml-sm-15 {
    margin-left: 15px; }
  .mr-sm-15 {
    margin-right: 15px; }
  .p-sm-20 {
    padding: 20px; }
  .py-sm-20 {
    padding-left: 20px;
    padding-right: 20px; }
  .px-sm-20 {
    padding-top: 20px;
    padding-bottom: 20px; }
  .pt-sm-20 {
    padding-top: 20px; }
  .pb-sm-20 {
    padding-bottom: 20px; }
  .pl-sm-20 {
    padding-left: 20px; }
  .pr-sm-20 {
    padding-right: 20px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-20 {
    margin: 20px; }
  .my-sm-20 {
    margin-left: 20px;
    margin-right: 20px; }
  .mx-sm-20 {
    margin-top: 20px;
    margin-bottom: 20px; }
  .mt-sm-20 {
    margin-top: 20px; }
  .mb-sm-20 {
    margin-bottom: 20px; }
  .ml-sm-20 {
    margin-left: 20px; }
  .mr-sm-20 {
    margin-right: 20px; }
  .p-sm-30 {
    padding: 30px; }
  .py-sm-30 {
    padding-left: 30px;
    padding-right: 30px; }
  .px-sm-30 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .pt-sm-30 {
    padding-top: 30px; }
  .pb-sm-30 {
    padding-bottom: 30px; }
  .pl-sm-30 {
    padding-left: 30px; }
  .pr-sm-30 {
    padding-right: 30px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-30 {
    margin: 30px; }
  .my-sm-30 {
    margin-left: 30px;
    margin-right: 30px; }
  .mx-sm-30 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mt-sm-30 {
    margin-top: 30px; }
  .mb-sm-30 {
    margin-bottom: 30px; }
  .ml-sm-30 {
    margin-left: 30px; }
  .mr-sm-30 {
    margin-right: 30px; }
  .p-sm-40 {
    padding: 40px; }
  .py-sm-40 {
    padding-left: 40px;
    padding-right: 40px; }
  .px-sm-40 {
    padding-top: 40px;
    padding-bottom: 40px; }
  .pt-sm-40 {
    padding-top: 40px; }
  .pb-sm-40 {
    padding-bottom: 40px; }
  .pl-sm-40 {
    padding-left: 40px; }
  .pr-sm-40 {
    padding-right: 40px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-40 {
    margin: 40px; }
  .my-sm-40 {
    margin-left: 40px;
    margin-right: 40px; }
  .mx-sm-40 {
    margin-top: 40px;
    margin-bottom: 40px; }
  .mt-sm-40 {
    margin-top: 40px; }
  .mb-sm-40 {
    margin-bottom: 40px; }
  .ml-sm-40 {
    margin-left: 40px; }
  .mr-sm-40 {
    margin-right: 40px; }
  .p-sm-50 {
    padding: 50px; }
  .py-sm-50 {
    padding-left: 50px;
    padding-right: 50px; }
  .px-sm-50 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .pt-sm-50 {
    padding-top: 50px; }
  .pb-sm-50 {
    padding-bottom: 50px; }
  .pl-sm-50 {
    padding-left: 50px; }
  .pr-sm-50 {
    padding-right: 50px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-50 {
    margin: 50px; }
  .my-sm-50 {
    margin-left: 50px;
    margin-right: 50px; }
  .mx-sm-50 {
    margin-top: 50px;
    margin-bottom: 50px; }
  .mt-sm-50 {
    margin-top: 50px; }
  .mb-sm-50 {
    margin-bottom: 50px; }
  .ml-sm-50 {
    margin-left: 50px; }
  .mr-sm-50 {
    margin-right: 50px; }
  .p-sm-60 {
    padding: 60px; }
  .py-sm-60 {
    padding-left: 60px;
    padding-right: 60px; }
  .px-sm-60 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .pt-sm-60 {
    padding-top: 60px; }
  .pb-sm-60 {
    padding-bottom: 60px; }
  .pl-sm-60 {
    padding-left: 60px; }
  .pr-sm-60 {
    padding-right: 60px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-60 {
    margin: 60px; }
  .my-sm-60 {
    margin-left: 60px;
    margin-right: 60px; }
  .mx-sm-60 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mt-sm-60 {
    margin-top: 60px; }
  .mb-sm-60 {
    margin-bottom: 60px; }
  .ml-sm-60 {
    margin-left: 60px; }
  .mr-sm-60 {
    margin-right: 60px; }
  .p-sm-70 {
    padding: 70px; }
  .py-sm-70 {
    padding-left: 70px;
    padding-right: 70px; }
  .px-sm-70 {
    padding-top: 70px;
    padding-bottom: 70px; }
  .pt-sm-70 {
    padding-top: 70px; }
  .pb-sm-70 {
    padding-bottom: 70px; }
  .pl-sm-70 {
    padding-left: 70px; }
  .pr-sm-70 {
    padding-right: 70px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-70 {
    margin: 70px; }
  .my-sm-70 {
    margin-left: 70px;
    margin-right: 70px; }
  .mx-sm-70 {
    margin-top: 70px;
    margin-bottom: 70px; }
  .mt-sm-70 {
    margin-top: 70px; }
  .mb-sm-70 {
    margin-bottom: 70px; }
  .ml-sm-70 {
    margin-left: 70px; }
  .mr-sm-70 {
    margin-right: 70px; }
  .p-sm-80 {
    padding: 80px; }
  .py-sm-80 {
    padding-left: 80px;
    padding-right: 80px; }
  .px-sm-80 {
    padding-top: 80px;
    padding-bottom: 80px; }
  .pt-sm-80 {
    padding-top: 80px; }
  .pb-sm-80 {
    padding-bottom: 80px; }
  .pl-sm-80 {
    padding-left: 80px; }
  .pr-sm-80 {
    padding-right: 80px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-80 {
    margin: 80px; }
  .my-sm-80 {
    margin-left: 80px;
    margin-right: 80px; }
  .mx-sm-80 {
    margin-top: 80px;
    margin-bottom: 80px; }
  .mt-sm-80 {
    margin-top: 80px; }
  .mb-sm-80 {
    margin-bottom: 80px; }
  .ml-sm-80 {
    margin-left: 80px; }
  .mr-sm-80 {
    margin-right: 80px; }
  .p-sm-90 {
    padding: 90px; }
  .py-sm-90 {
    padding-left: 90px;
    padding-right: 90px; }
  .px-sm-90 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .pt-sm-90 {
    padding-top: 90px; }
  .pb-sm-90 {
    padding-bottom: 90px; }
  .pl-sm-90 {
    padding-left: 90px; }
  .pr-sm-90 {
    padding-right: 90px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-90 {
    margin: 90px; }
  .my-sm-90 {
    margin-left: 90px;
    margin-right: 90px; }
  .mx-sm-90 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mt-sm-90 {
    margin-top: 90px; }
  .mb-sm-90 {
    margin-bottom: 90px; }
  .ml-sm-90 {
    margin-left: 90px; }
  .mr-sm-90 {
    margin-right: 90px; }
  .p-sm-100 {
    padding: 100px; }
  .py-sm-100 {
    padding-left: 100px;
    padding-right: 100px; }
  .px-sm-100 {
    padding-top: 100px;
    padding-bottom: 100px; }
  .pt-sm-100 {
    padding-top: 100px; }
  .pb-sm-100 {
    padding-bottom: 100px; }
  .pl-sm-100 {
    padding-left: 100px; }
  .pr-sm-100 {
    padding-right: 100px; }
  .m-sm-auto {
    margin: auto; }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-sm-auto {
    margin-top: auto; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .ml-sm-auto {
    margin-left: auto; }
  .mr-sm-auto {
    margin-right: auto; }
  .m-sm-100 {
    margin: 100px; }
  .my-sm-100 {
    margin-left: 100px;
    margin-right: 100px; }
  .mx-sm-100 {
    margin-top: 100px;
    margin-bottom: 100px; }
  .mt-sm-100 {
    margin-top: 100px; }
  .mb-sm-100 {
    margin-bottom: 100px; }
  .ml-sm-100 {
    margin-left: 100px; }
  .mr-sm-100 {
    margin-right: 100px; }
  .zindex-sm--1 {
    z-index: -1; }
  .zindex-sm-0 {
    z-index: 0; }
  .zindex-sm-10 {
    z-index: 10; }
  .zindex-sm-20 {
    z-index: 20; }
  .zindex-sm-30 {
    z-index: 30; }
  .zindex-sm-40 {
    z-index: 40; }
  .zindex-sm-50 {
    z-index: 50; }
  .zindex-sm-60 {
    z-index: 60; }
  .zindex-sm-70 {
    z-index: 70; }
  .zindex-sm-80 {
    z-index: 80; }
  .zindex-sm-90 {
    z-index: 90; }
  .zindex-sm-100 {
    z-index: 100; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 1; }
  .order-md-0 {
    order: 0; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .p-md-0 {
    padding: 0px; }
  .py-md-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .px-md-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .pt-md-0 {
    padding-top: 0px; }
  .pb-md-0 {
    padding-bottom: 0px; }
  .pl-md-0 {
    padding-left: 0px; }
  .pr-md-0 {
    padding-right: 0px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-0 {
    margin: 0px; }
  .my-md-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .mx-md-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mt-md-0 {
    margin-top: 0px; }
  .mb-md-0 {
    margin-bottom: 0px; }
  .ml-md-0 {
    margin-left: 0px; }
  .mr-md-0 {
    margin-right: 0px; }
  .p-md-5 {
    padding: 5px; }
  .py-md-5 {
    padding-left: 5px;
    padding-right: 5px; }
  .px-md-5 {
    padding-top: 5px;
    padding-bottom: 5px; }
  .pt-md-5 {
    padding-top: 5px; }
  .pb-md-5 {
    padding-bottom: 5px; }
  .pl-md-5 {
    padding-left: 5px; }
  .pr-md-5 {
    padding-right: 5px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-5 {
    margin: 5px; }
  .my-md-5 {
    margin-left: 5px;
    margin-right: 5px; }
  .mx-md-5 {
    margin-top: 5px;
    margin-bottom: 5px; }
  .mt-md-5 {
    margin-top: 5px; }
  .mb-md-5 {
    margin-bottom: 5px; }
  .ml-md-5 {
    margin-left: 5px; }
  .mr-md-5 {
    margin-right: 5px; }
  .p-md-10 {
    padding: 10px; }
  .py-md-10 {
    padding-left: 10px;
    padding-right: 10px; }
  .px-md-10 {
    padding-top: 10px;
    padding-bottom: 10px; }
  .pt-md-10 {
    padding-top: 10px; }
  .pb-md-10 {
    padding-bottom: 10px; }
  .pl-md-10 {
    padding-left: 10px; }
  .pr-md-10 {
    padding-right: 10px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-10 {
    margin: 10px; }
  .my-md-10 {
    margin-left: 10px;
    margin-right: 10px; }
  .mx-md-10 {
    margin-top: 10px;
    margin-bottom: 10px; }
  .mt-md-10 {
    margin-top: 10px; }
  .mb-md-10 {
    margin-bottom: 10px; }
  .ml-md-10 {
    margin-left: 10px; }
  .mr-md-10 {
    margin-right: 10px; }
  .p-md-15 {
    padding: 15px; }
  .py-md-15 {
    padding-left: 15px;
    padding-right: 15px; }
  .px-md-15 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .pt-md-15 {
    padding-top: 15px; }
  .pb-md-15 {
    padding-bottom: 15px; }
  .pl-md-15 {
    padding-left: 15px; }
  .pr-md-15 {
    padding-right: 15px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-15 {
    margin: 15px; }
  .my-md-15 {
    margin-left: 15px;
    margin-right: 15px; }
  .mx-md-15 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mt-md-15 {
    margin-top: 15px; }
  .mb-md-15 {
    margin-bottom: 15px; }
  .ml-md-15 {
    margin-left: 15px; }
  .mr-md-15 {
    margin-right: 15px; }
  .p-md-20 {
    padding: 20px; }
  .py-md-20 {
    padding-left: 20px;
    padding-right: 20px; }
  .px-md-20 {
    padding-top: 20px;
    padding-bottom: 20px; }
  .pt-md-20 {
    padding-top: 20px; }
  .pb-md-20 {
    padding-bottom: 20px; }
  .pl-md-20 {
    padding-left: 20px; }
  .pr-md-20 {
    padding-right: 20px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-20 {
    margin: 20px; }
  .my-md-20 {
    margin-left: 20px;
    margin-right: 20px; }
  .mx-md-20 {
    margin-top: 20px;
    margin-bottom: 20px; }
  .mt-md-20 {
    margin-top: 20px; }
  .mb-md-20 {
    margin-bottom: 20px; }
  .ml-md-20 {
    margin-left: 20px; }
  .mr-md-20 {
    margin-right: 20px; }
  .p-md-30 {
    padding: 30px; }
  .py-md-30 {
    padding-left: 30px;
    padding-right: 30px; }
  .px-md-30 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .pt-md-30 {
    padding-top: 30px; }
  .pb-md-30 {
    padding-bottom: 30px; }
  .pl-md-30 {
    padding-left: 30px; }
  .pr-md-30 {
    padding-right: 30px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-30 {
    margin: 30px; }
  .my-md-30 {
    margin-left: 30px;
    margin-right: 30px; }
  .mx-md-30 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mt-md-30 {
    margin-top: 30px; }
  .mb-md-30 {
    margin-bottom: 30px; }
  .ml-md-30 {
    margin-left: 30px; }
  .mr-md-30 {
    margin-right: 30px; }
  .p-md-40 {
    padding: 40px; }
  .py-md-40 {
    padding-left: 40px;
    padding-right: 40px; }
  .px-md-40 {
    padding-top: 40px;
    padding-bottom: 40px; }
  .pt-md-40 {
    padding-top: 40px; }
  .pb-md-40 {
    padding-bottom: 40px; }
  .pl-md-40 {
    padding-left: 40px; }
  .pr-md-40 {
    padding-right: 40px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-40 {
    margin: 40px; }
  .my-md-40 {
    margin-left: 40px;
    margin-right: 40px; }
  .mx-md-40 {
    margin-top: 40px;
    margin-bottom: 40px; }
  .mt-md-40 {
    margin-top: 40px; }
  .mb-md-40 {
    margin-bottom: 40px; }
  .ml-md-40 {
    margin-left: 40px; }
  .mr-md-40 {
    margin-right: 40px; }
  .p-md-50 {
    padding: 50px; }
  .py-md-50 {
    padding-left: 50px;
    padding-right: 50px; }
  .px-md-50 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .pt-md-50 {
    padding-top: 50px; }
  .pb-md-50 {
    padding-bottom: 50px; }
  .pl-md-50 {
    padding-left: 50px; }
  .pr-md-50 {
    padding-right: 50px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-50 {
    margin: 50px; }
  .my-md-50 {
    margin-left: 50px;
    margin-right: 50px; }
  .mx-md-50 {
    margin-top: 50px;
    margin-bottom: 50px; }
  .mt-md-50 {
    margin-top: 50px; }
  .mb-md-50 {
    margin-bottom: 50px; }
  .ml-md-50 {
    margin-left: 50px; }
  .mr-md-50 {
    margin-right: 50px; }
  .p-md-60 {
    padding: 60px; }
  .py-md-60 {
    padding-left: 60px;
    padding-right: 60px; }
  .px-md-60 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .pt-md-60 {
    padding-top: 60px; }
  .pb-md-60 {
    padding-bottom: 60px; }
  .pl-md-60 {
    padding-left: 60px; }
  .pr-md-60 {
    padding-right: 60px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-60 {
    margin: 60px; }
  .my-md-60 {
    margin-left: 60px;
    margin-right: 60px; }
  .mx-md-60 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mt-md-60 {
    margin-top: 60px; }
  .mb-md-60 {
    margin-bottom: 60px; }
  .ml-md-60 {
    margin-left: 60px; }
  .mr-md-60 {
    margin-right: 60px; }
  .p-md-70 {
    padding: 70px; }
  .py-md-70 {
    padding-left: 70px;
    padding-right: 70px; }
  .px-md-70 {
    padding-top: 70px;
    padding-bottom: 70px; }
  .pt-md-70 {
    padding-top: 70px; }
  .pb-md-70 {
    padding-bottom: 70px; }
  .pl-md-70 {
    padding-left: 70px; }
  .pr-md-70 {
    padding-right: 70px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-70 {
    margin: 70px; }
  .my-md-70 {
    margin-left: 70px;
    margin-right: 70px; }
  .mx-md-70 {
    margin-top: 70px;
    margin-bottom: 70px; }
  .mt-md-70 {
    margin-top: 70px; }
  .mb-md-70 {
    margin-bottom: 70px; }
  .ml-md-70 {
    margin-left: 70px; }
  .mr-md-70 {
    margin-right: 70px; }
  .p-md-80 {
    padding: 80px; }
  .py-md-80 {
    padding-left: 80px;
    padding-right: 80px; }
  .px-md-80 {
    padding-top: 80px;
    padding-bottom: 80px; }
  .pt-md-80 {
    padding-top: 80px; }
  .pb-md-80 {
    padding-bottom: 80px; }
  .pl-md-80 {
    padding-left: 80px; }
  .pr-md-80 {
    padding-right: 80px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-80 {
    margin: 80px; }
  .my-md-80 {
    margin-left: 80px;
    margin-right: 80px; }
  .mx-md-80 {
    margin-top: 80px;
    margin-bottom: 80px; }
  .mt-md-80 {
    margin-top: 80px; }
  .mb-md-80 {
    margin-bottom: 80px; }
  .ml-md-80 {
    margin-left: 80px; }
  .mr-md-80 {
    margin-right: 80px; }
  .p-md-90 {
    padding: 90px; }
  .py-md-90 {
    padding-left: 90px;
    padding-right: 90px; }
  .px-md-90 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .pt-md-90 {
    padding-top: 90px; }
  .pb-md-90 {
    padding-bottom: 90px; }
  .pl-md-90 {
    padding-left: 90px; }
  .pr-md-90 {
    padding-right: 90px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-90 {
    margin: 90px; }
  .my-md-90 {
    margin-left: 90px;
    margin-right: 90px; }
  .mx-md-90 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mt-md-90 {
    margin-top: 90px; }
  .mb-md-90 {
    margin-bottom: 90px; }
  .ml-md-90 {
    margin-left: 90px; }
  .mr-md-90 {
    margin-right: 90px; }
  .p-md-100 {
    padding: 100px; }
  .py-md-100 {
    padding-left: 100px;
    padding-right: 100px; }
  .px-md-100 {
    padding-top: 100px;
    padding-bottom: 100px; }
  .pt-md-100 {
    padding-top: 100px; }
  .pb-md-100 {
    padding-bottom: 100px; }
  .pl-md-100 {
    padding-left: 100px; }
  .pr-md-100 {
    padding-right: 100px; }
  .m-md-auto {
    margin: auto; }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-md-auto {
    margin-top: auto; }
  .mb-md-auto {
    margin-bottom: auto; }
  .ml-md-auto {
    margin-left: auto; }
  .mr-md-auto {
    margin-right: auto; }
  .m-md-100 {
    margin: 100px; }
  .my-md-100 {
    margin-left: 100px;
    margin-right: 100px; }
  .mx-md-100 {
    margin-top: 100px;
    margin-bottom: 100px; }
  .mt-md-100 {
    margin-top: 100px; }
  .mb-md-100 {
    margin-bottom: 100px; }
  .ml-md-100 {
    margin-left: 100px; }
  .mr-md-100 {
    margin-right: 100px; }
  .zindex-md--1 {
    z-index: -1; }
  .zindex-md-0 {
    z-index: 0; }
  .zindex-md-10 {
    z-index: 10; }
  .zindex-md-20 {
    z-index: 20; }
  .zindex-md-30 {
    z-index: 30; }
  .zindex-md-40 {
    z-index: 40; }
  .zindex-md-50 {
    z-index: 50; }
  .zindex-md-60 {
    z-index: 60; }
  .zindex-md-70 {
    z-index: 70; }
  .zindex-md-80 {
    z-index: 80; }
  .zindex-md-90 {
    z-index: 90; }
  .zindex-md-100 {
    z-index: 100; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 1; }
  .order-lg-0 {
    order: 0; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .p-lg-0 {
    padding: 0px; }
  .py-lg-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .px-lg-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .pt-lg-0 {
    padding-top: 0px; }
  .pb-lg-0 {
    padding-bottom: 0px; }
  .pl-lg-0 {
    padding-left: 0px; }
  .pr-lg-0 {
    padding-right: 0px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-0 {
    margin: 0px; }
  .my-lg-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .mx-lg-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mt-lg-0 {
    margin-top: 0px; }
  .mb-lg-0 {
    margin-bottom: 0px; }
  .ml-lg-0 {
    margin-left: 0px; }
  .mr-lg-0 {
    margin-right: 0px; }
  .p-lg-5 {
    padding: 5px; }
  .py-lg-5 {
    padding-left: 5px;
    padding-right: 5px; }
  .px-lg-5 {
    padding-top: 5px;
    padding-bottom: 5px; }
  .pt-lg-5 {
    padding-top: 5px; }
  .pb-lg-5 {
    padding-bottom: 5px; }
  .pl-lg-5 {
    padding-left: 5px; }
  .pr-lg-5 {
    padding-right: 5px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-5 {
    margin: 5px; }
  .my-lg-5 {
    margin-left: 5px;
    margin-right: 5px; }
  .mx-lg-5 {
    margin-top: 5px;
    margin-bottom: 5px; }
  .mt-lg-5 {
    margin-top: 5px; }
  .mb-lg-5 {
    margin-bottom: 5px; }
  .ml-lg-5 {
    margin-left: 5px; }
  .mr-lg-5 {
    margin-right: 5px; }
  .p-lg-10 {
    padding: 10px; }
  .py-lg-10 {
    padding-left: 10px;
    padding-right: 10px; }
  .px-lg-10 {
    padding-top: 10px;
    padding-bottom: 10px; }
  .pt-lg-10 {
    padding-top: 10px; }
  .pb-lg-10 {
    padding-bottom: 10px; }
  .pl-lg-10 {
    padding-left: 10px; }
  .pr-lg-10 {
    padding-right: 10px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-10 {
    margin: 10px; }
  .my-lg-10 {
    margin-left: 10px;
    margin-right: 10px; }
  .mx-lg-10 {
    margin-top: 10px;
    margin-bottom: 10px; }
  .mt-lg-10 {
    margin-top: 10px; }
  .mb-lg-10 {
    margin-bottom: 10px; }
  .ml-lg-10 {
    margin-left: 10px; }
  .mr-lg-10 {
    margin-right: 10px; }
  .p-lg-15 {
    padding: 15px; }
  .py-lg-15 {
    padding-left: 15px;
    padding-right: 15px; }
  .px-lg-15 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .pt-lg-15 {
    padding-top: 15px; }
  .pb-lg-15 {
    padding-bottom: 15px; }
  .pl-lg-15 {
    padding-left: 15px; }
  .pr-lg-15 {
    padding-right: 15px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-15 {
    margin: 15px; }
  .my-lg-15 {
    margin-left: 15px;
    margin-right: 15px; }
  .mx-lg-15 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mt-lg-15 {
    margin-top: 15px; }
  .mb-lg-15 {
    margin-bottom: 15px; }
  .ml-lg-15 {
    margin-left: 15px; }
  .mr-lg-15 {
    margin-right: 15px; }
  .p-lg-20 {
    padding: 20px; }
  .py-lg-20 {
    padding-left: 20px;
    padding-right: 20px; }
  .px-lg-20 {
    padding-top: 20px;
    padding-bottom: 20px; }
  .pt-lg-20 {
    padding-top: 20px; }
  .pb-lg-20 {
    padding-bottom: 20px; }
  .pl-lg-20 {
    padding-left: 20px; }
  .pr-lg-20 {
    padding-right: 20px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-20 {
    margin: 20px; }
  .my-lg-20 {
    margin-left: 20px;
    margin-right: 20px; }
  .mx-lg-20 {
    margin-top: 20px;
    margin-bottom: 20px; }
  .mt-lg-20 {
    margin-top: 20px; }
  .mb-lg-20 {
    margin-bottom: 20px; }
  .ml-lg-20 {
    margin-left: 20px; }
  .mr-lg-20 {
    margin-right: 20px; }
  .p-lg-30 {
    padding: 30px; }
  .py-lg-30 {
    padding-left: 30px;
    padding-right: 30px; }
  .px-lg-30 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .pt-lg-30 {
    padding-top: 30px; }
  .pb-lg-30 {
    padding-bottom: 30px; }
  .pl-lg-30 {
    padding-left: 30px; }
  .pr-lg-30 {
    padding-right: 30px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-30 {
    margin: 30px; }
  .my-lg-30 {
    margin-left: 30px;
    margin-right: 30px; }
  .mx-lg-30 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mt-lg-30 {
    margin-top: 30px; }
  .mb-lg-30 {
    margin-bottom: 30px; }
  .ml-lg-30 {
    margin-left: 30px; }
  .mr-lg-30 {
    margin-right: 30px; }
  .p-lg-40 {
    padding: 40px; }
  .py-lg-40 {
    padding-left: 40px;
    padding-right: 40px; }
  .px-lg-40 {
    padding-top: 40px;
    padding-bottom: 40px; }
  .pt-lg-40 {
    padding-top: 40px; }
  .pb-lg-40 {
    padding-bottom: 40px; }
  .pl-lg-40 {
    padding-left: 40px; }
  .pr-lg-40 {
    padding-right: 40px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-40 {
    margin: 40px; }
  .my-lg-40 {
    margin-left: 40px;
    margin-right: 40px; }
  .mx-lg-40 {
    margin-top: 40px;
    margin-bottom: 40px; }
  .mt-lg-40 {
    margin-top: 40px; }
  .mb-lg-40 {
    margin-bottom: 40px; }
  .ml-lg-40 {
    margin-left: 40px; }
  .mr-lg-40 {
    margin-right: 40px; }
  .p-lg-50 {
    padding: 50px; }
  .py-lg-50 {
    padding-left: 50px;
    padding-right: 50px; }
  .px-lg-50 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .pt-lg-50 {
    padding-top: 50px; }
  .pb-lg-50 {
    padding-bottom: 50px; }
  .pl-lg-50 {
    padding-left: 50px; }
  .pr-lg-50 {
    padding-right: 50px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-50 {
    margin: 50px; }
  .my-lg-50 {
    margin-left: 50px;
    margin-right: 50px; }
  .mx-lg-50 {
    margin-top: 50px;
    margin-bottom: 50px; }
  .mt-lg-50 {
    margin-top: 50px; }
  .mb-lg-50 {
    margin-bottom: 50px; }
  .ml-lg-50 {
    margin-left: 50px; }
  .mr-lg-50 {
    margin-right: 50px; }
  .p-lg-60 {
    padding: 60px; }
  .py-lg-60 {
    padding-left: 60px;
    padding-right: 60px; }
  .px-lg-60 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .pt-lg-60 {
    padding-top: 60px; }
  .pb-lg-60 {
    padding-bottom: 60px; }
  .pl-lg-60 {
    padding-left: 60px; }
  .pr-lg-60 {
    padding-right: 60px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-60 {
    margin: 60px; }
  .my-lg-60 {
    margin-left: 60px;
    margin-right: 60px; }
  .mx-lg-60 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mt-lg-60 {
    margin-top: 60px; }
  .mb-lg-60 {
    margin-bottom: 60px; }
  .ml-lg-60 {
    margin-left: 60px; }
  .mr-lg-60 {
    margin-right: 60px; }
  .p-lg-70 {
    padding: 70px; }
  .py-lg-70 {
    padding-left: 70px;
    padding-right: 70px; }
  .px-lg-70 {
    padding-top: 70px;
    padding-bottom: 70px; }
  .pt-lg-70 {
    padding-top: 70px; }
  .pb-lg-70 {
    padding-bottom: 70px; }
  .pl-lg-70 {
    padding-left: 70px; }
  .pr-lg-70 {
    padding-right: 70px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-70 {
    margin: 70px; }
  .my-lg-70 {
    margin-left: 70px;
    margin-right: 70px; }
  .mx-lg-70 {
    margin-top: 70px;
    margin-bottom: 70px; }
  .mt-lg-70 {
    margin-top: 70px; }
  .mb-lg-70 {
    margin-bottom: 70px; }
  .ml-lg-70 {
    margin-left: 70px; }
  .mr-lg-70 {
    margin-right: 70px; }
  .p-lg-80 {
    padding: 80px; }
  .py-lg-80 {
    padding-left: 80px;
    padding-right: 80px; }
  .px-lg-80 {
    padding-top: 80px;
    padding-bottom: 80px; }
  .pt-lg-80 {
    padding-top: 80px; }
  .pb-lg-80 {
    padding-bottom: 80px; }
  .pl-lg-80 {
    padding-left: 80px; }
  .pr-lg-80 {
    padding-right: 80px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-80 {
    margin: 80px; }
  .my-lg-80 {
    margin-left: 80px;
    margin-right: 80px; }
  .mx-lg-80 {
    margin-top: 80px;
    margin-bottom: 80px; }
  .mt-lg-80 {
    margin-top: 80px; }
  .mb-lg-80 {
    margin-bottom: 80px; }
  .ml-lg-80 {
    margin-left: 80px; }
  .mr-lg-80 {
    margin-right: 80px; }
  .p-lg-90 {
    padding: 90px; }
  .py-lg-90 {
    padding-left: 90px;
    padding-right: 90px; }
  .px-lg-90 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .pt-lg-90 {
    padding-top: 90px; }
  .pb-lg-90 {
    padding-bottom: 90px; }
  .pl-lg-90 {
    padding-left: 90px; }
  .pr-lg-90 {
    padding-right: 90px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-90 {
    margin: 90px; }
  .my-lg-90 {
    margin-left: 90px;
    margin-right: 90px; }
  .mx-lg-90 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mt-lg-90 {
    margin-top: 90px; }
  .mb-lg-90 {
    margin-bottom: 90px; }
  .ml-lg-90 {
    margin-left: 90px; }
  .mr-lg-90 {
    margin-right: 90px; }
  .p-lg-100 {
    padding: 100px; }
  .py-lg-100 {
    padding-left: 100px;
    padding-right: 100px; }
  .px-lg-100 {
    padding-top: 100px;
    padding-bottom: 100px; }
  .pt-lg-100 {
    padding-top: 100px; }
  .pb-lg-100 {
    padding-bottom: 100px; }
  .pl-lg-100 {
    padding-left: 100px; }
  .pr-lg-100 {
    padding-right: 100px; }
  .m-lg-auto {
    margin: auto; }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-lg-auto {
    margin-top: auto; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .ml-lg-auto {
    margin-left: auto; }
  .mr-lg-auto {
    margin-right: auto; }
  .m-lg-100 {
    margin: 100px; }
  .my-lg-100 {
    margin-left: 100px;
    margin-right: 100px; }
  .mx-lg-100 {
    margin-top: 100px;
    margin-bottom: 100px; }
  .mt-lg-100 {
    margin-top: 100px; }
  .mb-lg-100 {
    margin-bottom: 100px; }
  .ml-lg-100 {
    margin-left: 100px; }
  .mr-lg-100 {
    margin-right: 100px; }
  .zindex-lg--1 {
    z-index: -1; }
  .zindex-lg-0 {
    z-index: 0; }
  .zindex-lg-10 {
    z-index: 10; }
  .zindex-lg-20 {
    z-index: 20; }
  .zindex-lg-30 {
    z-index: 30; }
  .zindex-lg-40 {
    z-index: 40; }
  .zindex-lg-50 {
    z-index: 50; }
  .zindex-lg-60 {
    z-index: 60; }
  .zindex-lg-70 {
    z-index: 70; }
  .zindex-lg-80 {
    z-index: 80; }
  .zindex-lg-90 {
    z-index: 90; }
  .zindex-lg-100 {
    z-index: 100; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 1; }
  .order-xl-0 {
    order: 0; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .p-xl-0 {
    padding: 0px; }
  .py-xl-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .px-xl-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .pt-xl-0 {
    padding-top: 0px; }
  .pb-xl-0 {
    padding-bottom: 0px; }
  .pl-xl-0 {
    padding-left: 0px; }
  .pr-xl-0 {
    padding-right: 0px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-0 {
    margin: 0px; }
  .my-xl-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .mx-xl-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mt-xl-0 {
    margin-top: 0px; }
  .mb-xl-0 {
    margin-bottom: 0px; }
  .ml-xl-0 {
    margin-left: 0px; }
  .mr-xl-0 {
    margin-right: 0px; }
  .p-xl-5 {
    padding: 5px; }
  .py-xl-5 {
    padding-left: 5px;
    padding-right: 5px; }
  .px-xl-5 {
    padding-top: 5px;
    padding-bottom: 5px; }
  .pt-xl-5 {
    padding-top: 5px; }
  .pb-xl-5 {
    padding-bottom: 5px; }
  .pl-xl-5 {
    padding-left: 5px; }
  .pr-xl-5 {
    padding-right: 5px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-5 {
    margin: 5px; }
  .my-xl-5 {
    margin-left: 5px;
    margin-right: 5px; }
  .mx-xl-5 {
    margin-top: 5px;
    margin-bottom: 5px; }
  .mt-xl-5 {
    margin-top: 5px; }
  .mb-xl-5 {
    margin-bottom: 5px; }
  .ml-xl-5 {
    margin-left: 5px; }
  .mr-xl-5 {
    margin-right: 5px; }
  .p-xl-10 {
    padding: 10px; }
  .py-xl-10 {
    padding-left: 10px;
    padding-right: 10px; }
  .px-xl-10 {
    padding-top: 10px;
    padding-bottom: 10px; }
  .pt-xl-10 {
    padding-top: 10px; }
  .pb-xl-10 {
    padding-bottom: 10px; }
  .pl-xl-10 {
    padding-left: 10px; }
  .pr-xl-10 {
    padding-right: 10px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-10 {
    margin: 10px; }
  .my-xl-10 {
    margin-left: 10px;
    margin-right: 10px; }
  .mx-xl-10 {
    margin-top: 10px;
    margin-bottom: 10px; }
  .mt-xl-10 {
    margin-top: 10px; }
  .mb-xl-10 {
    margin-bottom: 10px; }
  .ml-xl-10 {
    margin-left: 10px; }
  .mr-xl-10 {
    margin-right: 10px; }
  .p-xl-15 {
    padding: 15px; }
  .py-xl-15 {
    padding-left: 15px;
    padding-right: 15px; }
  .px-xl-15 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .pt-xl-15 {
    padding-top: 15px; }
  .pb-xl-15 {
    padding-bottom: 15px; }
  .pl-xl-15 {
    padding-left: 15px; }
  .pr-xl-15 {
    padding-right: 15px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-15 {
    margin: 15px; }
  .my-xl-15 {
    margin-left: 15px;
    margin-right: 15px; }
  .mx-xl-15 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mt-xl-15 {
    margin-top: 15px; }
  .mb-xl-15 {
    margin-bottom: 15px; }
  .ml-xl-15 {
    margin-left: 15px; }
  .mr-xl-15 {
    margin-right: 15px; }
  .p-xl-20 {
    padding: 20px; }
  .py-xl-20 {
    padding-left: 20px;
    padding-right: 20px; }
  .px-xl-20 {
    padding-top: 20px;
    padding-bottom: 20px; }
  .pt-xl-20 {
    padding-top: 20px; }
  .pb-xl-20 {
    padding-bottom: 20px; }
  .pl-xl-20 {
    padding-left: 20px; }
  .pr-xl-20 {
    padding-right: 20px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-20 {
    margin: 20px; }
  .my-xl-20 {
    margin-left: 20px;
    margin-right: 20px; }
  .mx-xl-20 {
    margin-top: 20px;
    margin-bottom: 20px; }
  .mt-xl-20 {
    margin-top: 20px; }
  .mb-xl-20 {
    margin-bottom: 20px; }
  .ml-xl-20 {
    margin-left: 20px; }
  .mr-xl-20 {
    margin-right: 20px; }
  .p-xl-30 {
    padding: 30px; }
  .py-xl-30 {
    padding-left: 30px;
    padding-right: 30px; }
  .px-xl-30 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .pt-xl-30 {
    padding-top: 30px; }
  .pb-xl-30 {
    padding-bottom: 30px; }
  .pl-xl-30 {
    padding-left: 30px; }
  .pr-xl-30 {
    padding-right: 30px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-30 {
    margin: 30px; }
  .my-xl-30 {
    margin-left: 30px;
    margin-right: 30px; }
  .mx-xl-30 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mt-xl-30 {
    margin-top: 30px; }
  .mb-xl-30 {
    margin-bottom: 30px; }
  .ml-xl-30 {
    margin-left: 30px; }
  .mr-xl-30 {
    margin-right: 30px; }
  .p-xl-40 {
    padding: 40px; }
  .py-xl-40 {
    padding-left: 40px;
    padding-right: 40px; }
  .px-xl-40 {
    padding-top: 40px;
    padding-bottom: 40px; }
  .pt-xl-40 {
    padding-top: 40px; }
  .pb-xl-40 {
    padding-bottom: 40px; }
  .pl-xl-40 {
    padding-left: 40px; }
  .pr-xl-40 {
    padding-right: 40px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-40 {
    margin: 40px; }
  .my-xl-40 {
    margin-left: 40px;
    margin-right: 40px; }
  .mx-xl-40 {
    margin-top: 40px;
    margin-bottom: 40px; }
  .mt-xl-40 {
    margin-top: 40px; }
  .mb-xl-40 {
    margin-bottom: 40px; }
  .ml-xl-40 {
    margin-left: 40px; }
  .mr-xl-40 {
    margin-right: 40px; }
  .p-xl-50 {
    padding: 50px; }
  .py-xl-50 {
    padding-left: 50px;
    padding-right: 50px; }
  .px-xl-50 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .pt-xl-50 {
    padding-top: 50px; }
  .pb-xl-50 {
    padding-bottom: 50px; }
  .pl-xl-50 {
    padding-left: 50px; }
  .pr-xl-50 {
    padding-right: 50px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-50 {
    margin: 50px; }
  .my-xl-50 {
    margin-left: 50px;
    margin-right: 50px; }
  .mx-xl-50 {
    margin-top: 50px;
    margin-bottom: 50px; }
  .mt-xl-50 {
    margin-top: 50px; }
  .mb-xl-50 {
    margin-bottom: 50px; }
  .ml-xl-50 {
    margin-left: 50px; }
  .mr-xl-50 {
    margin-right: 50px; }
  .p-xl-60 {
    padding: 60px; }
  .py-xl-60 {
    padding-left: 60px;
    padding-right: 60px; }
  .px-xl-60 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .pt-xl-60 {
    padding-top: 60px; }
  .pb-xl-60 {
    padding-bottom: 60px; }
  .pl-xl-60 {
    padding-left: 60px; }
  .pr-xl-60 {
    padding-right: 60px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-60 {
    margin: 60px; }
  .my-xl-60 {
    margin-left: 60px;
    margin-right: 60px; }
  .mx-xl-60 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mt-xl-60 {
    margin-top: 60px; }
  .mb-xl-60 {
    margin-bottom: 60px; }
  .ml-xl-60 {
    margin-left: 60px; }
  .mr-xl-60 {
    margin-right: 60px; }
  .p-xl-70 {
    padding: 70px; }
  .py-xl-70 {
    padding-left: 70px;
    padding-right: 70px; }
  .px-xl-70 {
    padding-top: 70px;
    padding-bottom: 70px; }
  .pt-xl-70 {
    padding-top: 70px; }
  .pb-xl-70 {
    padding-bottom: 70px; }
  .pl-xl-70 {
    padding-left: 70px; }
  .pr-xl-70 {
    padding-right: 70px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-70 {
    margin: 70px; }
  .my-xl-70 {
    margin-left: 70px;
    margin-right: 70px; }
  .mx-xl-70 {
    margin-top: 70px;
    margin-bottom: 70px; }
  .mt-xl-70 {
    margin-top: 70px; }
  .mb-xl-70 {
    margin-bottom: 70px; }
  .ml-xl-70 {
    margin-left: 70px; }
  .mr-xl-70 {
    margin-right: 70px; }
  .p-xl-80 {
    padding: 80px; }
  .py-xl-80 {
    padding-left: 80px;
    padding-right: 80px; }
  .px-xl-80 {
    padding-top: 80px;
    padding-bottom: 80px; }
  .pt-xl-80 {
    padding-top: 80px; }
  .pb-xl-80 {
    padding-bottom: 80px; }
  .pl-xl-80 {
    padding-left: 80px; }
  .pr-xl-80 {
    padding-right: 80px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-80 {
    margin: 80px; }
  .my-xl-80 {
    margin-left: 80px;
    margin-right: 80px; }
  .mx-xl-80 {
    margin-top: 80px;
    margin-bottom: 80px; }
  .mt-xl-80 {
    margin-top: 80px; }
  .mb-xl-80 {
    margin-bottom: 80px; }
  .ml-xl-80 {
    margin-left: 80px; }
  .mr-xl-80 {
    margin-right: 80px; }
  .p-xl-90 {
    padding: 90px; }
  .py-xl-90 {
    padding-left: 90px;
    padding-right: 90px; }
  .px-xl-90 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .pt-xl-90 {
    padding-top: 90px; }
  .pb-xl-90 {
    padding-bottom: 90px; }
  .pl-xl-90 {
    padding-left: 90px; }
  .pr-xl-90 {
    padding-right: 90px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-90 {
    margin: 90px; }
  .my-xl-90 {
    margin-left: 90px;
    margin-right: 90px; }
  .mx-xl-90 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mt-xl-90 {
    margin-top: 90px; }
  .mb-xl-90 {
    margin-bottom: 90px; }
  .ml-xl-90 {
    margin-left: 90px; }
  .mr-xl-90 {
    margin-right: 90px; }
  .p-xl-100 {
    padding: 100px; }
  .py-xl-100 {
    padding-left: 100px;
    padding-right: 100px; }
  .px-xl-100 {
    padding-top: 100px;
    padding-bottom: 100px; }
  .pt-xl-100 {
    padding-top: 100px; }
  .pb-xl-100 {
    padding-bottom: 100px; }
  .pl-xl-100 {
    padding-left: 100px; }
  .pr-xl-100 {
    padding-right: 100px; }
  .m-xl-auto {
    margin: auto; }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xl-auto {
    margin-top: auto; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .ml-xl-auto {
    margin-left: auto; }
  .mr-xl-auto {
    margin-right: auto; }
  .m-xl-100 {
    margin: 100px; }
  .my-xl-100 {
    margin-left: 100px;
    margin-right: 100px; }
  .mx-xl-100 {
    margin-top: 100px;
    margin-bottom: 100px; }
  .mt-xl-100 {
    margin-top: 100px; }
  .mb-xl-100 {
    margin-bottom: 100px; }
  .ml-xl-100 {
    margin-left: 100px; }
  .mr-xl-100 {
    margin-right: 100px; }
  .zindex-xl--1 {
    z-index: -1; }
  .zindex-xl-0 {
    z-index: 0; }
  .zindex-xl-10 {
    z-index: 10; }
  .zindex-xl-20 {
    z-index: 20; }
  .zindex-xl-30 {
    z-index: 30; }
  .zindex-xl-40 {
    z-index: 40; }
  .zindex-xl-50 {
    z-index: 50; }
  .zindex-xl-60 {
    z-index: 60; }
  .zindex-xl-70 {
    z-index: 70; }
  .zindex-xl-80 {
    z-index: 80; }
  .zindex-xl-90 {
    z-index: 90; }
  .zindex-xl-100 {
    z-index: 100; } }

@media (min-width: 1500px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 1; }
  .order-xxl-0 {
    order: 0; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .p-xxl-0 {
    padding: 0px; }
  .py-xxl-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .px-xxl-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .pt-xxl-0 {
    padding-top: 0px; }
  .pb-xxl-0 {
    padding-bottom: 0px; }
  .pl-xxl-0 {
    padding-left: 0px; }
  .pr-xxl-0 {
    padding-right: 0px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-0 {
    margin: 0px; }
  .my-xxl-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .mx-xxl-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mt-xxl-0 {
    margin-top: 0px; }
  .mb-xxl-0 {
    margin-bottom: 0px; }
  .ml-xxl-0 {
    margin-left: 0px; }
  .mr-xxl-0 {
    margin-right: 0px; }
  .p-xxl-5 {
    padding: 5px; }
  .py-xxl-5 {
    padding-left: 5px;
    padding-right: 5px; }
  .px-xxl-5 {
    padding-top: 5px;
    padding-bottom: 5px; }
  .pt-xxl-5 {
    padding-top: 5px; }
  .pb-xxl-5 {
    padding-bottom: 5px; }
  .pl-xxl-5 {
    padding-left: 5px; }
  .pr-xxl-5 {
    padding-right: 5px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-5 {
    margin: 5px; }
  .my-xxl-5 {
    margin-left: 5px;
    margin-right: 5px; }
  .mx-xxl-5 {
    margin-top: 5px;
    margin-bottom: 5px; }
  .mt-xxl-5 {
    margin-top: 5px; }
  .mb-xxl-5 {
    margin-bottom: 5px; }
  .ml-xxl-5 {
    margin-left: 5px; }
  .mr-xxl-5 {
    margin-right: 5px; }
  .p-xxl-10 {
    padding: 10px; }
  .py-xxl-10 {
    padding-left: 10px;
    padding-right: 10px; }
  .px-xxl-10 {
    padding-top: 10px;
    padding-bottom: 10px; }
  .pt-xxl-10 {
    padding-top: 10px; }
  .pb-xxl-10 {
    padding-bottom: 10px; }
  .pl-xxl-10 {
    padding-left: 10px; }
  .pr-xxl-10 {
    padding-right: 10px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-10 {
    margin: 10px; }
  .my-xxl-10 {
    margin-left: 10px;
    margin-right: 10px; }
  .mx-xxl-10 {
    margin-top: 10px;
    margin-bottom: 10px; }
  .mt-xxl-10 {
    margin-top: 10px; }
  .mb-xxl-10 {
    margin-bottom: 10px; }
  .ml-xxl-10 {
    margin-left: 10px; }
  .mr-xxl-10 {
    margin-right: 10px; }
  .p-xxl-15 {
    padding: 15px; }
  .py-xxl-15 {
    padding-left: 15px;
    padding-right: 15px; }
  .px-xxl-15 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .pt-xxl-15 {
    padding-top: 15px; }
  .pb-xxl-15 {
    padding-bottom: 15px; }
  .pl-xxl-15 {
    padding-left: 15px; }
  .pr-xxl-15 {
    padding-right: 15px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-15 {
    margin: 15px; }
  .my-xxl-15 {
    margin-left: 15px;
    margin-right: 15px; }
  .mx-xxl-15 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mt-xxl-15 {
    margin-top: 15px; }
  .mb-xxl-15 {
    margin-bottom: 15px; }
  .ml-xxl-15 {
    margin-left: 15px; }
  .mr-xxl-15 {
    margin-right: 15px; }
  .p-xxl-20 {
    padding: 20px; }
  .py-xxl-20 {
    padding-left: 20px;
    padding-right: 20px; }
  .px-xxl-20 {
    padding-top: 20px;
    padding-bottom: 20px; }
  .pt-xxl-20 {
    padding-top: 20px; }
  .pb-xxl-20 {
    padding-bottom: 20px; }
  .pl-xxl-20 {
    padding-left: 20px; }
  .pr-xxl-20 {
    padding-right: 20px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-20 {
    margin: 20px; }
  .my-xxl-20 {
    margin-left: 20px;
    margin-right: 20px; }
  .mx-xxl-20 {
    margin-top: 20px;
    margin-bottom: 20px; }
  .mt-xxl-20 {
    margin-top: 20px; }
  .mb-xxl-20 {
    margin-bottom: 20px; }
  .ml-xxl-20 {
    margin-left: 20px; }
  .mr-xxl-20 {
    margin-right: 20px; }
  .p-xxl-30 {
    padding: 30px; }
  .py-xxl-30 {
    padding-left: 30px;
    padding-right: 30px; }
  .px-xxl-30 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .pt-xxl-30 {
    padding-top: 30px; }
  .pb-xxl-30 {
    padding-bottom: 30px; }
  .pl-xxl-30 {
    padding-left: 30px; }
  .pr-xxl-30 {
    padding-right: 30px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-30 {
    margin: 30px; }
  .my-xxl-30 {
    margin-left: 30px;
    margin-right: 30px; }
  .mx-xxl-30 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mt-xxl-30 {
    margin-top: 30px; }
  .mb-xxl-30 {
    margin-bottom: 30px; }
  .ml-xxl-30 {
    margin-left: 30px; }
  .mr-xxl-30 {
    margin-right: 30px; }
  .p-xxl-40 {
    padding: 40px; }
  .py-xxl-40 {
    padding-left: 40px;
    padding-right: 40px; }
  .px-xxl-40 {
    padding-top: 40px;
    padding-bottom: 40px; }
  .pt-xxl-40 {
    padding-top: 40px; }
  .pb-xxl-40 {
    padding-bottom: 40px; }
  .pl-xxl-40 {
    padding-left: 40px; }
  .pr-xxl-40 {
    padding-right: 40px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-40 {
    margin: 40px; }
  .my-xxl-40 {
    margin-left: 40px;
    margin-right: 40px; }
  .mx-xxl-40 {
    margin-top: 40px;
    margin-bottom: 40px; }
  .mt-xxl-40 {
    margin-top: 40px; }
  .mb-xxl-40 {
    margin-bottom: 40px; }
  .ml-xxl-40 {
    margin-left: 40px; }
  .mr-xxl-40 {
    margin-right: 40px; }
  .p-xxl-50 {
    padding: 50px; }
  .py-xxl-50 {
    padding-left: 50px;
    padding-right: 50px; }
  .px-xxl-50 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .pt-xxl-50 {
    padding-top: 50px; }
  .pb-xxl-50 {
    padding-bottom: 50px; }
  .pl-xxl-50 {
    padding-left: 50px; }
  .pr-xxl-50 {
    padding-right: 50px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-50 {
    margin: 50px; }
  .my-xxl-50 {
    margin-left: 50px;
    margin-right: 50px; }
  .mx-xxl-50 {
    margin-top: 50px;
    margin-bottom: 50px; }
  .mt-xxl-50 {
    margin-top: 50px; }
  .mb-xxl-50 {
    margin-bottom: 50px; }
  .ml-xxl-50 {
    margin-left: 50px; }
  .mr-xxl-50 {
    margin-right: 50px; }
  .p-xxl-60 {
    padding: 60px; }
  .py-xxl-60 {
    padding-left: 60px;
    padding-right: 60px; }
  .px-xxl-60 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .pt-xxl-60 {
    padding-top: 60px; }
  .pb-xxl-60 {
    padding-bottom: 60px; }
  .pl-xxl-60 {
    padding-left: 60px; }
  .pr-xxl-60 {
    padding-right: 60px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-60 {
    margin: 60px; }
  .my-xxl-60 {
    margin-left: 60px;
    margin-right: 60px; }
  .mx-xxl-60 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mt-xxl-60 {
    margin-top: 60px; }
  .mb-xxl-60 {
    margin-bottom: 60px; }
  .ml-xxl-60 {
    margin-left: 60px; }
  .mr-xxl-60 {
    margin-right: 60px; }
  .p-xxl-70 {
    padding: 70px; }
  .py-xxl-70 {
    padding-left: 70px;
    padding-right: 70px; }
  .px-xxl-70 {
    padding-top: 70px;
    padding-bottom: 70px; }
  .pt-xxl-70 {
    padding-top: 70px; }
  .pb-xxl-70 {
    padding-bottom: 70px; }
  .pl-xxl-70 {
    padding-left: 70px; }
  .pr-xxl-70 {
    padding-right: 70px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-70 {
    margin: 70px; }
  .my-xxl-70 {
    margin-left: 70px;
    margin-right: 70px; }
  .mx-xxl-70 {
    margin-top: 70px;
    margin-bottom: 70px; }
  .mt-xxl-70 {
    margin-top: 70px; }
  .mb-xxl-70 {
    margin-bottom: 70px; }
  .ml-xxl-70 {
    margin-left: 70px; }
  .mr-xxl-70 {
    margin-right: 70px; }
  .p-xxl-80 {
    padding: 80px; }
  .py-xxl-80 {
    padding-left: 80px;
    padding-right: 80px; }
  .px-xxl-80 {
    padding-top: 80px;
    padding-bottom: 80px; }
  .pt-xxl-80 {
    padding-top: 80px; }
  .pb-xxl-80 {
    padding-bottom: 80px; }
  .pl-xxl-80 {
    padding-left: 80px; }
  .pr-xxl-80 {
    padding-right: 80px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-80 {
    margin: 80px; }
  .my-xxl-80 {
    margin-left: 80px;
    margin-right: 80px; }
  .mx-xxl-80 {
    margin-top: 80px;
    margin-bottom: 80px; }
  .mt-xxl-80 {
    margin-top: 80px; }
  .mb-xxl-80 {
    margin-bottom: 80px; }
  .ml-xxl-80 {
    margin-left: 80px; }
  .mr-xxl-80 {
    margin-right: 80px; }
  .p-xxl-90 {
    padding: 90px; }
  .py-xxl-90 {
    padding-left: 90px;
    padding-right: 90px; }
  .px-xxl-90 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .pt-xxl-90 {
    padding-top: 90px; }
  .pb-xxl-90 {
    padding-bottom: 90px; }
  .pl-xxl-90 {
    padding-left: 90px; }
  .pr-xxl-90 {
    padding-right: 90px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-90 {
    margin: 90px; }
  .my-xxl-90 {
    margin-left: 90px;
    margin-right: 90px; }
  .mx-xxl-90 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mt-xxl-90 {
    margin-top: 90px; }
  .mb-xxl-90 {
    margin-bottom: 90px; }
  .ml-xxl-90 {
    margin-left: 90px; }
  .mr-xxl-90 {
    margin-right: 90px; }
  .p-xxl-100 {
    padding: 100px; }
  .py-xxl-100 {
    padding-left: 100px;
    padding-right: 100px; }
  .px-xxl-100 {
    padding-top: 100px;
    padding-bottom: 100px; }
  .pt-xxl-100 {
    padding-top: 100px; }
  .pb-xxl-100 {
    padding-bottom: 100px; }
  .pl-xxl-100 {
    padding-left: 100px; }
  .pr-xxl-100 {
    padding-right: 100px; }
  .m-xxl-auto {
    margin: auto; }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto; }
  .mt-xxl-auto {
    margin-top: auto; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .ml-xxl-auto {
    margin-left: auto; }
  .mr-xxl-auto {
    margin-right: auto; }
  .m-xxl-100 {
    margin: 100px; }
  .my-xxl-100 {
    margin-left: 100px;
    margin-right: 100px; }
  .mx-xxl-100 {
    margin-top: 100px;
    margin-bottom: 100px; }
  .mt-xxl-100 {
    margin-top: 100px; }
  .mb-xxl-100 {
    margin-bottom: 100px; }
  .ml-xxl-100 {
    margin-left: 100px; }
  .mr-xxl-100 {
    margin-right: 100px; }
  .zindex-xxl--1 {
    z-index: -1; }
  .zindex-xxl-0 {
    z-index: 0; }
  .zindex-xxl-10 {
    z-index: 10; }
  .zindex-xxl-20 {
    z-index: 20; }
  .zindex-xxl-30 {
    z-index: 30; }
  .zindex-xxl-40 {
    z-index: 40; }
  .zindex-xxl-50 {
    z-index: 50; }
  .zindex-xxl-60 {
    z-index: 60; }
  .zindex-xxl-70 {
    z-index: 70; }
  .zindex-xxl-80 {
    z-index: 80; }
  .zindex-xxl-90 {
    z-index: 90; }
  .zindex-xxl-100 {
    z-index: 100; } }

a {
  color: #fdad20;
  text-decoration: none; }
  a.link-secondary {
    color: #333333; }
  a a:link a:visited {
    color: inherit; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  @media (hover: hover) {
    a:not([href]):not([tabindex]):hover {
      color: inherit;
      text-decoration: none; } }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

.fc-primary {
  color: #fdad20 !important; }

.bg-primary {
  background-color: #fdad20 !important; }

.border-primary {
  border: 1px solid #fdad20; }

.icon-primary {
  stroke: #fdad20; }
  .icon-primary svg {
    stroke: #fdad20; }

.fc-secondary {
  color: #333333 !important; }

.bg-secondary {
  background-color: #333333 !important; }

.border-secondary {
  border: 1px solid #333333; }

.icon-secondary {
  stroke: #333333; }
  .icon-secondary svg {
    stroke: #333333; }

.fc-tertiary {
  color: #0c4e82 !important; }

.bg-tertiary {
  background-color: #0c4e82 !important; }

.border-tertiary {
  border: 1px solid #0c4e82; }

.icon-tertiary {
  stroke: #0c4e82; }
  .icon-tertiary svg {
    stroke: #0c4e82; }

.fc-black {
  color: #000000 !important; }

.bg-black {
  background-color: #000000 !important; }

.border-black {
  border: 1px solid #000000; }

.icon-black {
  stroke: #000000; }
  .icon-black svg {
    stroke: #000000; }

.fc-darkgrey {
  color: #9B9B9B !important; }

.bg-darkgrey {
  background-color: #9B9B9B !important; }

.border-darkgrey {
  border: 1px solid #9B9B9B; }

.icon-darkgrey {
  stroke: #9B9B9B; }
  .icon-darkgrey svg {
    stroke: #9B9B9B; }

.fc-lightgrey {
  color: #EEEEEE !important; }

.bg-lightgrey {
  background-color: #EEEEEE !important; }

.border-lightgrey {
  border: 1px solid #EEEEEE; }

.icon-lightgrey {
  stroke: #EEEEEE; }
  .icon-lightgrey svg {
    stroke: #EEEEEE; }

.fc-white {
  color: #FFFFFF !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.border-white {
  border: 1px solid #FFFFFF; }

.icon-white {
  stroke: #FFFFFF; }
  .icon-white svg {
    stroke: #FFFFFF; }

.fc-error {
  color: #D0021B !important; }

.bg-error {
  background-color: #D0021B !important; }

.border-error {
  border: 1px solid #D0021B; }

.icon-error {
  stroke: #D0021B; }
  .icon-error svg {
    stroke: #D0021B; }

.fc-warning {
  color: #F5A623 !important; }

.bg-warning {
  background-color: #F5A623 !important; }

.border-warning {
  border: 1px solid #F5A623; }

.icon-warning {
  stroke: #F5A623; }
  .icon-warning svg {
    stroke: #F5A623; }

.fc-success {
  color: #7ED321 !important; }

.bg-success {
  background-color: #7ED321 !important; }

.border-success {
  border: 1px solid #7ED321; }

.icon-success {
  stroke: #7ED321; }
  .icon-success svg {
    stroke: #7ED321; }

.fc-disabled {
  color: #EEEEEE !important; }

.bg-disabled {
  background-color: #EEEEEE !important; }

.border-disabled {
  border: 1px solid #EEEEEE; }

.icon-disabled {
  stroke: #EEEEEE; }
  .icon-disabled svg {
    stroke: #EEEEEE; }

.fc-bodycolor {
  color: #333333 !important; }

.bg-bodycolor {
  background-color: #333333 !important; }

.border-bodycolor {
  border: 1px solid #333333; }

.icon-bodycolor {
  stroke: #333333; }
  .icon-bodycolor svg {
    stroke: #333333; }

.fc-headingcolor {
  color: #333333 !important; }

.bg-headingcolor {
  background-color: #333333 !important; }

.border-headingcolor {
  border: 1px solid #333333; }

.icon-headingcolor {
  stroke: #333333; }
  .icon-headingcolor svg {
    stroke: #333333; }

@media (hover: hover) {
  a {
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    transition: all .2s ease; }
    a.link-secondary:hover {
      color: #595959; } }

body {
  -moz-font-feature-settings: 'liga', 'kern';
  -moz-font-feature-settings: 'liga=1, kern=1';
  -ms-font-feature-settings: 'liga', 'kern';
  -o-font-feature-settings: 'liga', 'kern';
  -webkit-font-feature-settings: 'liga', 'kern';
  font-feature-settings: 'liga', 'kern';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-stretch: normal;
  color: #333333;
  font-family: "Muli", sans-serif; }
  @media (min-width: 1500px) {
    body {
      font-size: 20; } }

.heading, h1, .fs-1, h2, .fs-2, h3, .fs-3, label, input, .fs-7 {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  color: #333333;
  line-height: 36; }
  .heading strong, h1 strong, .fs-1 strong, h2 strong, .fs-2 strong, h3 strong, .fs-3 strong, label strong, input strong, .fs-7 strong {
    font-weight: 400; }

.body, h4, .fs-4, h5, p, .fs-5, h6, .fs-6, small, .fs-8 {
  font-family: "Muli", sans-serif;
  font-weight: 300;
  color: #333333; }

h1, .fs-1 {
  font-size: 2.625rem;
  line-height: 60px; }
  @media (max-width: 1200px) {
    h1, .fs-1 {
      font-size: calc(1.1625rem + 1.95vw) ; } }

h2, .fs-2 {
  font-size: 28px;
  line-height: 40px; }
  @media (min-width: 1500px) {
    h2, .fs-2 {
      font-size: 2.375rem !important;
      line-height: 48px; } }
  @media (min-width: 1500px) and (max-width: 1200px) {
    h2, .fs-2 {
      font-size: calc(1.1375rem + 1.65vw)  !important; } }

h3, .fs-3 {
  font-size: 1.75rem;
  line-height: 42px; }
  @media (max-width: 1200px) {
    h3, .fs-3 {
      font-size: calc(1.075rem + 0.9vw) ; } }

h4, .fs-4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px; }
  @media (min-width: 1500px) {
    h4, .fs-4 {
      font-size: 1.625rem;
      line-height: 36px; } }
  @media (min-width: 1500px) and (max-width: 1200px) {
    h4, .fs-4 {
      font-size: calc(1.0625rem + 0.75vw) ; } }
  @media (min-width: 1500px) {
      h4.small, .fs-4.small {
        font-size: 24px; } }

h5, p, .fs-5 {
  font-size: 16;
  line-height: 28px; }
  @media (min-width: 1500px) {
    h5, p, .fs-5 {
      font-size: 1.25rem;
      line-height: 36px; } }
  @media (min-width: 1500px) and (max-width: 1200px) {
    h5, p, .fs-5 {
      font-size: calc(1.025rem + 0.3vw) ; } }

h6, .fs-6 {
  font-size: 1.125rem;
  line-height: 1.7; }
  @media (max-width: 1200px) {
    h6, .fs-6 {
      font-size: calc(1.0125rem + 0.15vw) ; } }

label, input, .fs-7 {
  font-size: 1rem;
  line-height: 30px; }

small, .fs-8 {
  font-size: 0.875rem; }

.fw-300 {
  font-weight: 300; }

.fw-400 {
  font-weight: 400; }

.fw-600, strong, b {
  font-weight: 600; }

.fw-800 {
  font-weight: 800; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-italic {
  font-style: italic; }

.text-underline {
  text-decoration: underline; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 400px) {
  .text-xs-left {
    text-align: left !important; }
  .text-xs-right {
    text-align: right !important; }
  .text-xs-center {
    text-align: center !important; } }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1500px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

ul, ol {
  list-style: none;
  padding: 0; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

hr {
  border: 0;
  border-bottom: 1px solid #f6f8fb;
  height: 1px;
  background: none; }

.team a:hover {
  opacity: .9; }

.main-wrapper {
  width: 100%;
  overflow-x: hidden; }

.fc-tartiary {
  color: #78b3e2; }

.fs-large {
  font-size: 24px;
  font-weight: bold; }

.icon {
  display: inline-block; }

.icon-background {
  background-repeat: no-repeat;
  background-position: center center; }

.icon-rotate-90 {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.icon-rotate-180 {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.icon-rotate-270 {
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.icon-16 {
  width: 16px;
  height: 16px;
  background-size: 16px 16px; }

.icon-20 {
  width: 20px;
  height: 20px;
  background-size: 20px 20px; }

.icon-32 {
  width: 32px;
  height: 32px;
  background-size: 32px 32px; }

.icon-40 {
  width: 40px;
  height: 40px;
  background-size: 40px 40px; }

.icon-44 {
  width: 44px;
  height: 44px;
  background-size: 44px 44px; }

.icon-50 {
  width: 50px;
  height: 50px;
  background-size: 50px 50px; }

.icon-64 {
  width: 64px;
  height: 64px;
  background-size: 64px 64px; }

.icon-70 {
  width: 70px;
  height: 70px;
  background-size: 70px 70px; }

.icon-80 {
  width: 80px;
  height: 80px;
  background-size: 80px 80px; }

.icon-100 {
  width: 100px;
  height: 100px;
  background-size: 100px 100px; }

.icon-128 {
  width: 128px;
  height: 128px;
  background-size: 128px 128px; }

.icon-150 {
  width: 150px;
  height: 150px;
  background-size: 150px 150px; }

.logo {
  display: block;
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  background: url(assets/img/brand/logo/logo-_chatbot_white.png) no-repeat center center;
  background-size: 231px 35px;
  width: 231px;
  height: 35px; }
  @media (max-width: 767px) {
    .logo {
      background-size: 132px 25px;
      width: 132px;
      height: 25px; } }
  @media (min-width: 1500px) {
    .logo {
      background-size: 297px 45px;
      width: 297px;
      height: 45px; } }
  .logo.center {
    margin: 0 auto; }
    @media (min-width: 768px) {
      .logo.center {
        margin: 0; } }
  .logo.secondary {
    background: url(assets/img/brand/logo/logo-_chatbot_blue.png) no-repeat center center;
    background-size: 297px 45px; }

.btn,
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  transition: all 300ms linear;
  border-radius: 50px;
  background-clip: padding-box;
  display: inline-block;
  padding: 12.5px 61.6px;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 28px;
  font-weight: 600;
  text-transform: capitalize;
  color: white;
  background-color: #fdad20;
  background-clip: border-box;
  border: 1px solid #fdad20;
  min-width: 10px;
  text-decoration: none;
  text-align: center;
  width: auto;
  appearance: none;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-appearance: none; }
  @media (max-width: 991px) {
    .btn,
    button,
    input[type="button"],
    input[type="submit"],
    input[type="reset"] {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 1500px) {
    .btn,
    button,
    input[type="button"],
    input[type="submit"],
    input[type="reset"] {
      padding: 29px 61.6px;
      font-size: 24px; } }

.btn.btn-primary,
button.btn-primary,
input[type="button"].btn-primary,
input[type="submit"].btn-primary,
input[type="reset"].btn-primary {
  background-color: #fdad20;
  color: #FFFFFF;
  border-color: #fdad20; }
  .btn.btn-primary.btn-outline,
  button.btn-primary.btn-outline,
  input[type="button"].btn-primary.btn-outline,
  input[type="submit"].btn-primary.btn-outline,
  input[type="reset"].btn-primary.btn-outline {
    background-color: transparent;
    color: #fdad20; }

@media (hover: hover) {
  .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary.active, .btn.btn-primary.is-active,
  button.btn-primary:hover,
  button.btn-primary:focus,
  button.btn-primary:active,
  button.btn-primary.active,
  button.btn-primary.is-active,
  input[type="button"].btn-primary:hover,
  input[type="button"].btn-primary:focus,
  input[type="button"].btn-primary:active,
  input[type="button"].btn-primary.active,
  input[type="button"].btn-primary.is-active,
  input[type="submit"].btn-primary:hover,
  input[type="submit"].btn-primary:focus,
  input[type="submit"].btn-primary:active,
  input[type="submit"].btn-primary.active,
  input[type="submit"].btn-primary.is-active,
  input[type="reset"].btn-primary:hover,
  input[type="reset"].btn-primary:focus,
  input[type="reset"].btn-primary:active,
  input[type="reset"].btn-primary.active,
  input[type="reset"].btn-primary.is-active {
    border: 1px solid #fdc053;
    background: #fdc053; }
    .btn.btn-primary:hover.btn-outline, .btn.btn-primary:focus.btn-outline, .btn.btn-primary:active.btn-outline, .btn.btn-primary.active.btn-outline, .btn.btn-primary.is-active.btn-outline,
    button.btn-primary:hover.btn-outline,
    button.btn-primary:focus.btn-outline,
    button.btn-primary:active.btn-outline,
    button.btn-primary.active.btn-outline,
    button.btn-primary.is-active.btn-outline,
    input[type="button"].btn-primary:hover.btn-outline,
    input[type="button"].btn-primary:focus.btn-outline,
    input[type="button"].btn-primary:active.btn-outline,
    input[type="button"].btn-primary.active.btn-outline,
    input[type="button"].btn-primary.is-active.btn-outline,
    input[type="submit"].btn-primary:hover.btn-outline,
    input[type="submit"].btn-primary:focus.btn-outline,
    input[type="submit"].btn-primary:active.btn-outline,
    input[type="submit"].btn-primary.active.btn-outline,
    input[type="submit"].btn-primary.is-active.btn-outline,
    input[type="reset"].btn-primary:hover.btn-outline,
    input[type="reset"].btn-primary:focus.btn-outline,
    input[type="reset"].btn-primary:active.btn-outline,
    input[type="reset"].btn-primary.active.btn-outline,
    input[type="reset"].btn-primary.is-active.btn-outline {
      border: 1px solid #fdad20;
      background: #fdad20;
      color: #FFFFFF; } }

.btn.btn-secondary,
button.btn-secondary,
input[type="button"].btn-secondary,
input[type="submit"].btn-secondary,
input[type="reset"].btn-secondary {
  background-color: #333333;
  color: #FFFFFF;
  border-color: #333333; }
  .btn.btn-secondary.btn-outline,
  button.btn-secondary.btn-outline,
  input[type="button"].btn-secondary.btn-outline,
  input[type="submit"].btn-secondary.btn-outline,
  input[type="reset"].btn-secondary.btn-outline {
    background-color: transparent;
    color: #333333; }

@media (hover: hover) {
  .btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary:active, .btn.btn-secondary.active, .btn.btn-secondary.is-active,
  button.btn-secondary:hover,
  button.btn-secondary:focus,
  button.btn-secondary:active,
  button.btn-secondary.active,
  button.btn-secondary.is-active,
  input[type="button"].btn-secondary:hover,
  input[type="button"].btn-secondary:focus,
  input[type="button"].btn-secondary:active,
  input[type="button"].btn-secondary.active,
  input[type="button"].btn-secondary.is-active,
  input[type="submit"].btn-secondary:hover,
  input[type="submit"].btn-secondary:focus,
  input[type="submit"].btn-secondary:active,
  input[type="submit"].btn-secondary.active,
  input[type="submit"].btn-secondary.is-active,
  input[type="reset"].btn-secondary:hover,
  input[type="reset"].btn-secondary:focus,
  input[type="reset"].btn-secondary:active,
  input[type="reset"].btn-secondary.active,
  input[type="reset"].btn-secondary.is-active {
    border: 1px solid #4d4d4d;
    background: #4d4d4d; }
    .btn.btn-secondary:hover.btn-outline, .btn.btn-secondary:focus.btn-outline, .btn.btn-secondary:active.btn-outline, .btn.btn-secondary.active.btn-outline, .btn.btn-secondary.is-active.btn-outline,
    button.btn-secondary:hover.btn-outline,
    button.btn-secondary:focus.btn-outline,
    button.btn-secondary:active.btn-outline,
    button.btn-secondary.active.btn-outline,
    button.btn-secondary.is-active.btn-outline,
    input[type="button"].btn-secondary:hover.btn-outline,
    input[type="button"].btn-secondary:focus.btn-outline,
    input[type="button"].btn-secondary:active.btn-outline,
    input[type="button"].btn-secondary.active.btn-outline,
    input[type="button"].btn-secondary.is-active.btn-outline,
    input[type="submit"].btn-secondary:hover.btn-outline,
    input[type="submit"].btn-secondary:focus.btn-outline,
    input[type="submit"].btn-secondary:active.btn-outline,
    input[type="submit"].btn-secondary.active.btn-outline,
    input[type="submit"].btn-secondary.is-active.btn-outline,
    input[type="reset"].btn-secondary:hover.btn-outline,
    input[type="reset"].btn-secondary:focus.btn-outline,
    input[type="reset"].btn-secondary:active.btn-outline,
    input[type="reset"].btn-secondary.active.btn-outline,
    input[type="reset"].btn-secondary.is-active.btn-outline {
      border: 1px solid #333333;
      background: #333333;
      color: #FFFFFF; } }

.btn.btn-black,
button.btn-black,
input[type="button"].btn-black,
input[type="submit"].btn-black,
input[type="reset"].btn-black {
  background-color: #000000;
  color: #FFFFFF;
  border-color: #000000; }
  .btn.btn-black.btn-outline,
  button.btn-black.btn-outline,
  input[type="button"].btn-black.btn-outline,
  input[type="submit"].btn-black.btn-outline,
  input[type="reset"].btn-black.btn-outline {
    background-color: transparent;
    color: #000000; }

@media (hover: hover) {
  .btn.btn-black:hover, .btn.btn-black:focus, .btn.btn-black:active, .btn.btn-black.active, .btn.btn-black.is-active,
  button.btn-black:hover,
  button.btn-black:focus,
  button.btn-black:active,
  button.btn-black.active,
  button.btn-black.is-active,
  input[type="button"].btn-black:hover,
  input[type="button"].btn-black:focus,
  input[type="button"].btn-black:active,
  input[type="button"].btn-black.active,
  input[type="button"].btn-black.is-active,
  input[type="submit"].btn-black:hover,
  input[type="submit"].btn-black:focus,
  input[type="submit"].btn-black:active,
  input[type="submit"].btn-black.active,
  input[type="submit"].btn-black.is-active,
  input[type="reset"].btn-black:hover,
  input[type="reset"].btn-black:focus,
  input[type="reset"].btn-black:active,
  input[type="reset"].btn-black.active,
  input[type="reset"].btn-black.is-active {
    border: 1px solid #1a1a1a;
    background: #1a1a1a; }
    .btn.btn-black:hover.btn-outline, .btn.btn-black:focus.btn-outline, .btn.btn-black:active.btn-outline, .btn.btn-black.active.btn-outline, .btn.btn-black.is-active.btn-outline,
    button.btn-black:hover.btn-outline,
    button.btn-black:focus.btn-outline,
    button.btn-black:active.btn-outline,
    button.btn-black.active.btn-outline,
    button.btn-black.is-active.btn-outline,
    input[type="button"].btn-black:hover.btn-outline,
    input[type="button"].btn-black:focus.btn-outline,
    input[type="button"].btn-black:active.btn-outline,
    input[type="button"].btn-black.active.btn-outline,
    input[type="button"].btn-black.is-active.btn-outline,
    input[type="submit"].btn-black:hover.btn-outline,
    input[type="submit"].btn-black:focus.btn-outline,
    input[type="submit"].btn-black:active.btn-outline,
    input[type="submit"].btn-black.active.btn-outline,
    input[type="submit"].btn-black.is-active.btn-outline,
    input[type="reset"].btn-black:hover.btn-outline,
    input[type="reset"].btn-black:focus.btn-outline,
    input[type="reset"].btn-black:active.btn-outline,
    input[type="reset"].btn-black.active.btn-outline,
    input[type="reset"].btn-black.is-active.btn-outline {
      border: 1px solid #000000;
      background: #000000;
      color: #FFFFFF; } }

.btn.btn-darkgrey,
button.btn-darkgrey,
input[type="button"].btn-darkgrey,
input[type="submit"].btn-darkgrey,
input[type="reset"].btn-darkgrey {
  background-color: #9B9B9B;
  color: #FFFFFF;
  border-color: #9B9B9B; }
  .btn.btn-darkgrey.btn-outline,
  button.btn-darkgrey.btn-outline,
  input[type="button"].btn-darkgrey.btn-outline,
  input[type="submit"].btn-darkgrey.btn-outline,
  input[type="reset"].btn-darkgrey.btn-outline {
    background-color: transparent;
    color: #9B9B9B; }

@media (hover: hover) {
  .btn.btn-darkgrey:hover, .btn.btn-darkgrey:focus, .btn.btn-darkgrey:active, .btn.btn-darkgrey.active, .btn.btn-darkgrey.is-active,
  button.btn-darkgrey:hover,
  button.btn-darkgrey:focus,
  button.btn-darkgrey:active,
  button.btn-darkgrey.active,
  button.btn-darkgrey.is-active,
  input[type="button"].btn-darkgrey:hover,
  input[type="button"].btn-darkgrey:focus,
  input[type="button"].btn-darkgrey:active,
  input[type="button"].btn-darkgrey.active,
  input[type="button"].btn-darkgrey.is-active,
  input[type="submit"].btn-darkgrey:hover,
  input[type="submit"].btn-darkgrey:focus,
  input[type="submit"].btn-darkgrey:active,
  input[type="submit"].btn-darkgrey.active,
  input[type="submit"].btn-darkgrey.is-active,
  input[type="reset"].btn-darkgrey:hover,
  input[type="reset"].btn-darkgrey:focus,
  input[type="reset"].btn-darkgrey:active,
  input[type="reset"].btn-darkgrey.active,
  input[type="reset"].btn-darkgrey.is-active {
    border: 1px solid #b5b5b5;
    background: #b5b5b5; }
    .btn.btn-darkgrey:hover.btn-outline, .btn.btn-darkgrey:focus.btn-outline, .btn.btn-darkgrey:active.btn-outline, .btn.btn-darkgrey.active.btn-outline, .btn.btn-darkgrey.is-active.btn-outline,
    button.btn-darkgrey:hover.btn-outline,
    button.btn-darkgrey:focus.btn-outline,
    button.btn-darkgrey:active.btn-outline,
    button.btn-darkgrey.active.btn-outline,
    button.btn-darkgrey.is-active.btn-outline,
    input[type="button"].btn-darkgrey:hover.btn-outline,
    input[type="button"].btn-darkgrey:focus.btn-outline,
    input[type="button"].btn-darkgrey:active.btn-outline,
    input[type="button"].btn-darkgrey.active.btn-outline,
    input[type="button"].btn-darkgrey.is-active.btn-outline,
    input[type="submit"].btn-darkgrey:hover.btn-outline,
    input[type="submit"].btn-darkgrey:focus.btn-outline,
    input[type="submit"].btn-darkgrey:active.btn-outline,
    input[type="submit"].btn-darkgrey.active.btn-outline,
    input[type="submit"].btn-darkgrey.is-active.btn-outline,
    input[type="reset"].btn-darkgrey:hover.btn-outline,
    input[type="reset"].btn-darkgrey:focus.btn-outline,
    input[type="reset"].btn-darkgrey:active.btn-outline,
    input[type="reset"].btn-darkgrey.active.btn-outline,
    input[type="reset"].btn-darkgrey.is-active.btn-outline {
      border: 1px solid #9B9B9B;
      background: #9B9B9B;
      color: #FFFFFF; } }

.btn.btn-lightgrey,
button.btn-lightgrey,
input[type="button"].btn-lightgrey,
input[type="submit"].btn-lightgrey,
input[type="reset"].btn-lightgrey {
  background-color: #EEEEEE;
  color: #000000;
  border-color: #EEEEEE; }
  .btn.btn-lightgrey.btn-outline,
  button.btn-lightgrey.btn-outline,
  input[type="button"].btn-lightgrey.btn-outline,
  input[type="submit"].btn-lightgrey.btn-outline,
  input[type="reset"].btn-lightgrey.btn-outline {
    background-color: transparent;
    color: #EEEEEE; }

@media (hover: hover) {
  .btn.btn-lightgrey:hover, .btn.btn-lightgrey:focus, .btn.btn-lightgrey:active, .btn.btn-lightgrey.active, .btn.btn-lightgrey.is-active,
  button.btn-lightgrey:hover,
  button.btn-lightgrey:focus,
  button.btn-lightgrey:active,
  button.btn-lightgrey.active,
  button.btn-lightgrey.is-active,
  input[type="button"].btn-lightgrey:hover,
  input[type="button"].btn-lightgrey:focus,
  input[type="button"].btn-lightgrey:active,
  input[type="button"].btn-lightgrey.active,
  input[type="button"].btn-lightgrey.is-active,
  input[type="submit"].btn-lightgrey:hover,
  input[type="submit"].btn-lightgrey:focus,
  input[type="submit"].btn-lightgrey:active,
  input[type="submit"].btn-lightgrey.active,
  input[type="submit"].btn-lightgrey.is-active,
  input[type="reset"].btn-lightgrey:hover,
  input[type="reset"].btn-lightgrey:focus,
  input[type="reset"].btn-lightgrey:active,
  input[type="reset"].btn-lightgrey.active,
  input[type="reset"].btn-lightgrey.is-active {
    border: 1px solid white;
    background: white; }
    .btn.btn-lightgrey:hover.btn-outline, .btn.btn-lightgrey:focus.btn-outline, .btn.btn-lightgrey:active.btn-outline, .btn.btn-lightgrey.active.btn-outline, .btn.btn-lightgrey.is-active.btn-outline,
    button.btn-lightgrey:hover.btn-outline,
    button.btn-lightgrey:focus.btn-outline,
    button.btn-lightgrey:active.btn-outline,
    button.btn-lightgrey.active.btn-outline,
    button.btn-lightgrey.is-active.btn-outline,
    input[type="button"].btn-lightgrey:hover.btn-outline,
    input[type="button"].btn-lightgrey:focus.btn-outline,
    input[type="button"].btn-lightgrey:active.btn-outline,
    input[type="button"].btn-lightgrey.active.btn-outline,
    input[type="button"].btn-lightgrey.is-active.btn-outline,
    input[type="submit"].btn-lightgrey:hover.btn-outline,
    input[type="submit"].btn-lightgrey:focus.btn-outline,
    input[type="submit"].btn-lightgrey:active.btn-outline,
    input[type="submit"].btn-lightgrey.active.btn-outline,
    input[type="submit"].btn-lightgrey.is-active.btn-outline,
    input[type="reset"].btn-lightgrey:hover.btn-outline,
    input[type="reset"].btn-lightgrey:focus.btn-outline,
    input[type="reset"].btn-lightgrey:active.btn-outline,
    input[type="reset"].btn-lightgrey.active.btn-outline,
    input[type="reset"].btn-lightgrey.is-active.btn-outline {
      border: 1px solid #EEEEEE;
      background: #EEEEEE;
      color: #000000; } }

.btn.btn-white,
button.btn-white,
input[type="button"].btn-white,
input[type="submit"].btn-white,
input[type="reset"].btn-white {
  background-color: #FFFFFF;
  color: #fdad20;
  border-color: #FFFFFF; }
  .btn.btn-white.btn-outline,
  button.btn-white.btn-outline,
  input[type="button"].btn-white.btn-outline,
  input[type="submit"].btn-white.btn-outline,
  input[type="reset"].btn-white.btn-outline {
    background-color: transparent;
    color: #FFFFFF; }

@media (hover: hover) {
  .btn.btn-white:hover, .btn.btn-white:focus, .btn.btn-white:active, .btn.btn-white.active, .btn.btn-white.is-active,
  button.btn-white:hover,
  button.btn-white:focus,
  button.btn-white:active,
  button.btn-white.active,
  button.btn-white.is-active,
  input[type="button"].btn-white:hover,
  input[type="button"].btn-white:focus,
  input[type="button"].btn-white:active,
  input[type="button"].btn-white.active,
  input[type="button"].btn-white.is-active,
  input[type="submit"].btn-white:hover,
  input[type="submit"].btn-white:focus,
  input[type="submit"].btn-white:active,
  input[type="submit"].btn-white.active,
  input[type="submit"].btn-white.is-active,
  input[type="reset"].btn-white:hover,
  input[type="reset"].btn-white:focus,
  input[type="reset"].btn-white:active,
  input[type="reset"].btn-white.active,
  input[type="reset"].btn-white.is-active {
    border: 1px solid white;
    background: white; }
    .btn.btn-white:hover.btn-outline, .btn.btn-white:focus.btn-outline, .btn.btn-white:active.btn-outline, .btn.btn-white.active.btn-outline, .btn.btn-white.is-active.btn-outline,
    button.btn-white:hover.btn-outline,
    button.btn-white:focus.btn-outline,
    button.btn-white:active.btn-outline,
    button.btn-white.active.btn-outline,
    button.btn-white.is-active.btn-outline,
    input[type="button"].btn-white:hover.btn-outline,
    input[type="button"].btn-white:focus.btn-outline,
    input[type="button"].btn-white:active.btn-outline,
    input[type="button"].btn-white.active.btn-outline,
    input[type="button"].btn-white.is-active.btn-outline,
    input[type="submit"].btn-white:hover.btn-outline,
    input[type="submit"].btn-white:focus.btn-outline,
    input[type="submit"].btn-white:active.btn-outline,
    input[type="submit"].btn-white.active.btn-outline,
    input[type="submit"].btn-white.is-active.btn-outline,
    input[type="reset"].btn-white:hover.btn-outline,
    input[type="reset"].btn-white:focus.btn-outline,
    input[type="reset"].btn-white:active.btn-outline,
    input[type="reset"].btn-white.active.btn-outline,
    input[type="reset"].btn-white.is-active.btn-outline {
      border: 1px solid #FFFFFF;
      background: #FFFFFF;
      color: #fdad20; } }

.btn.btn-danger,
button.btn-danger,
input[type="button"].btn-danger,
input[type="submit"].btn-danger,
input[type="reset"].btn-danger {
  background-color: #D0021B;
  color: #FFFFFF;
  border-color: #D0021B; }
  .btn.btn-danger.btn-outline,
  button.btn-danger.btn-outline,
  input[type="button"].btn-danger.btn-outline,
  input[type="submit"].btn-danger.btn-outline,
  input[type="reset"].btn-danger.btn-outline {
    background-color: transparent;
    color: #D0021B; }

@media (hover: hover) {
  .btn.btn-danger:hover, .btn.btn-danger:focus, .btn.btn-danger:active, .btn.btn-danger.active, .btn.btn-danger.is-active,
  button.btn-danger:hover,
  button.btn-danger:focus,
  button.btn-danger:active,
  button.btn-danger.active,
  button.btn-danger.is-active,
  input[type="button"].btn-danger:hover,
  input[type="button"].btn-danger:focus,
  input[type="button"].btn-danger:active,
  input[type="button"].btn-danger.active,
  input[type="button"].btn-danger.is-active,
  input[type="submit"].btn-danger:hover,
  input[type="submit"].btn-danger:focus,
  input[type="submit"].btn-danger:active,
  input[type="submit"].btn-danger.active,
  input[type="submit"].btn-danger.is-active,
  input[type="reset"].btn-danger:hover,
  input[type="reset"].btn-danger:focus,
  input[type="reset"].btn-danger:active,
  input[type="reset"].btn-danger.active,
  input[type="reset"].btn-danger.is-active {
    border: 1px solid #fd0826;
    background: #fd0826; }
    .btn.btn-danger:hover.btn-outline, .btn.btn-danger:focus.btn-outline, .btn.btn-danger:active.btn-outline, .btn.btn-danger.active.btn-outline, .btn.btn-danger.is-active.btn-outline,
    button.btn-danger:hover.btn-outline,
    button.btn-danger:focus.btn-outline,
    button.btn-danger:active.btn-outline,
    button.btn-danger.active.btn-outline,
    button.btn-danger.is-active.btn-outline,
    input[type="button"].btn-danger:hover.btn-outline,
    input[type="button"].btn-danger:focus.btn-outline,
    input[type="button"].btn-danger:active.btn-outline,
    input[type="button"].btn-danger.active.btn-outline,
    input[type="button"].btn-danger.is-active.btn-outline,
    input[type="submit"].btn-danger:hover.btn-outline,
    input[type="submit"].btn-danger:focus.btn-outline,
    input[type="submit"].btn-danger:active.btn-outline,
    input[type="submit"].btn-danger.active.btn-outline,
    input[type="submit"].btn-danger.is-active.btn-outline,
    input[type="reset"].btn-danger:hover.btn-outline,
    input[type="reset"].btn-danger:focus.btn-outline,
    input[type="reset"].btn-danger:active.btn-outline,
    input[type="reset"].btn-danger.active.btn-outline,
    input[type="reset"].btn-danger.is-active.btn-outline {
      border: 1px solid #D0021B;
      background: #D0021B;
      color: #FFFFFF; } }

.btn.btn-warning,
button.btn-warning,
input[type="button"].btn-warning,
input[type="submit"].btn-warning,
input[type="reset"].btn-warning {
  background-color: #F5A623;
  color: #FFFFFF;
  border-color: #F5A623; }
  .btn.btn-warning.btn-outline,
  button.btn-warning.btn-outline,
  input[type="button"].btn-warning.btn-outline,
  input[type="submit"].btn-warning.btn-outline,
  input[type="reset"].btn-warning.btn-outline {
    background-color: transparent;
    color: #F5A623; }

@media (hover: hover) {
  .btn.btn-warning:hover, .btn.btn-warning:focus, .btn.btn-warning:active, .btn.btn-warning.active, .btn.btn-warning.is-active,
  button.btn-warning:hover,
  button.btn-warning:focus,
  button.btn-warning:active,
  button.btn-warning.active,
  button.btn-warning.is-active,
  input[type="button"].btn-warning:hover,
  input[type="button"].btn-warning:focus,
  input[type="button"].btn-warning:active,
  input[type="button"].btn-warning.active,
  input[type="button"].btn-warning.is-active,
  input[type="submit"].btn-warning:hover,
  input[type="submit"].btn-warning:focus,
  input[type="submit"].btn-warning:active,
  input[type="submit"].btn-warning.active,
  input[type="submit"].btn-warning.is-active,
  input[type="reset"].btn-warning:hover,
  input[type="reset"].btn-warning:focus,
  input[type="reset"].btn-warning:active,
  input[type="reset"].btn-warning.active,
  input[type="reset"].btn-warning.is-active {
    border: 1px solid #f7ba54;
    background: #f7ba54; }
    .btn.btn-warning:hover.btn-outline, .btn.btn-warning:focus.btn-outline, .btn.btn-warning:active.btn-outline, .btn.btn-warning.active.btn-outline, .btn.btn-warning.is-active.btn-outline,
    button.btn-warning:hover.btn-outline,
    button.btn-warning:focus.btn-outline,
    button.btn-warning:active.btn-outline,
    button.btn-warning.active.btn-outline,
    button.btn-warning.is-active.btn-outline,
    input[type="button"].btn-warning:hover.btn-outline,
    input[type="button"].btn-warning:focus.btn-outline,
    input[type="button"].btn-warning:active.btn-outline,
    input[type="button"].btn-warning.active.btn-outline,
    input[type="button"].btn-warning.is-active.btn-outline,
    input[type="submit"].btn-warning:hover.btn-outline,
    input[type="submit"].btn-warning:focus.btn-outline,
    input[type="submit"].btn-warning:active.btn-outline,
    input[type="submit"].btn-warning.active.btn-outline,
    input[type="submit"].btn-warning.is-active.btn-outline,
    input[type="reset"].btn-warning:hover.btn-outline,
    input[type="reset"].btn-warning:focus.btn-outline,
    input[type="reset"].btn-warning:active.btn-outline,
    input[type="reset"].btn-warning.active.btn-outline,
    input[type="reset"].btn-warning.is-active.btn-outline {
      border: 1px solid #F5A623;
      background: #F5A623;
      color: #FFFFFF; } }

.btn.btn-success,
button.btn-success,
input[type="button"].btn-success,
input[type="submit"].btn-success,
input[type="reset"].btn-success {
  background-color: #7ED321;
  color: #FFFFFF;
  border-color: #7ED321; }
  .btn.btn-success.btn-outline,
  button.btn-success.btn-outline,
  input[type="button"].btn-success.btn-outline,
  input[type="submit"].btn-success.btn-outline,
  input[type="reset"].btn-success.btn-outline {
    background-color: transparent;
    color: #7ED321; }

@media (hover: hover) {
  .btn.btn-success:hover, .btn.btn-success:focus, .btn.btn-success:active, .btn.btn-success.active, .btn.btn-success.is-active,
  button.btn-success:hover,
  button.btn-success:focus,
  button.btn-success:active,
  button.btn-success.active,
  button.btn-success.is-active,
  input[type="button"].btn-success:hover,
  input[type="button"].btn-success:focus,
  input[type="button"].btn-success:active,
  input[type="button"].btn-success.active,
  input[type="button"].btn-success.is-active,
  input[type="submit"].btn-success:hover,
  input[type="submit"].btn-success:focus,
  input[type="submit"].btn-success:active,
  input[type="submit"].btn-success.active,
  input[type="submit"].btn-success.is-active,
  input[type="reset"].btn-success:hover,
  input[type="reset"].btn-success:focus,
  input[type="reset"].btn-success:active,
  input[type="reset"].btn-success.active,
  input[type="reset"].btn-success.is-active {
    border: 1px solid #97e245;
    background: #97e245; }
    .btn.btn-success:hover.btn-outline, .btn.btn-success:focus.btn-outline, .btn.btn-success:active.btn-outline, .btn.btn-success.active.btn-outline, .btn.btn-success.is-active.btn-outline,
    button.btn-success:hover.btn-outline,
    button.btn-success:focus.btn-outline,
    button.btn-success:active.btn-outline,
    button.btn-success.active.btn-outline,
    button.btn-success.is-active.btn-outline,
    input[type="button"].btn-success:hover.btn-outline,
    input[type="button"].btn-success:focus.btn-outline,
    input[type="button"].btn-success:active.btn-outline,
    input[type="button"].btn-success.active.btn-outline,
    input[type="button"].btn-success.is-active.btn-outline,
    input[type="submit"].btn-success:hover.btn-outline,
    input[type="submit"].btn-success:focus.btn-outline,
    input[type="submit"].btn-success:active.btn-outline,
    input[type="submit"].btn-success.active.btn-outline,
    input[type="submit"].btn-success.is-active.btn-outline,
    input[type="reset"].btn-success:hover.btn-outline,
    input[type="reset"].btn-success:focus.btn-outline,
    input[type="reset"].btn-success:active.btn-outline,
    input[type="reset"].btn-success.active.btn-outline,
    input[type="reset"].btn-success.is-active.btn-outline {
      border: 1px solid #7ED321;
      background: #7ED321;
      color: #FFFFFF; } }

.btn.btn-wide,
button.btn-wide,
input[type="button"].btn-wide,
input[type="submit"].btn-wide,
input[type="reset"].btn-wide {
  display: block; }

.btn .icon,
button .icon,
input[type="button"] .icon,
input[type="submit"] .icon,
input[type="reset"] .icon {
  position: relative;
  top: 2px; }

.btn-small {
  height: 58px;
  font-size: 18px;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px; }
  @media (min-width: 1500px) {
    .btn-small {
      font-size: 24px; } }
  .btn-small.block {
    width: 100% !important; }

.btn-inverse {
  min-width: 300px;
  height: 50px;
  border: 2px solid #fdad20;
  background: transparent;
  color: #fdad20;
  padding: 0;
  font-size: 16px; }
  @media (min-width: 1500px) {
    .btn-inverse {
      min-width: 396px;
      height: 70px;
      border: 3px solid #fdad20;
      font-size: 24px; } }
  .btn-inverse:hover {
    background: #fdad20;
    color: #FFFFFF; }

button[disabled], .btn-disabled {
  background: #EEEEEE !important;
  border: 1px solid #EEEEEE !important;
  color: white !important;
  cursor: not-allowed !important;
  border-color: #EEEEEE !important; }

img.responsive {
  width: 100%;
  height: auto;
  max-width: 100%; }

img.rounded {
  border-radius: 6px;
  background-clip: padding-box; }

img.circle {
  border-radius: 100%;
  background-clip: padding-box; }

img.thumbnail {
  padding: 5px;
  border: 1px solid #f6f8fb; }

figure.image {
  position: relative; }
  figure.image .preload {
    position: absolute;
    z-index: -1;
    width: 100%; }
  figure.image .lozad {
    opacity: 0;
    transition: opacity 500ms ease 100ms; }
    figure.image .lozad[data-loaded] {
      opacity: 1; }

.bg-image {
  background-repeat: no-repeat;
  background-size: 100% auto; }
  .bg-image.cover {
    background-size: cover; }
  .bg-image.contain {
    background-size: contain; }

.aspect::before {
  display: block;
  content: ""; }

.aspect.aspect-21by9::before {
  padding-top: 42.85714%; }

.aspect.aspect-16by9::before {
  padding-top: 56.25%; }

.aspect.aspect-4by3::before {
  padding-top: 75%; }

.aspect.aspect-1by1::before {
  padding-top: 100%; }

.banner {
  position: relative;
  background-size: cover !important;
  height: auto; }
  .banner header {
    width: 100%; }
  .banner.single .banner-content {
    padding-top: 120px;
    padding-bottom: 60px; }
  .banner.single:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(12, 78, 130, 0.77); }
  .banner.inner-page header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; }
  @media (min-width: 1500px) {
    .banner.inner-page .banner-menu img {
      height: 70px; } }
  .banner.inner-page .banner-content {
    display: block;
    padding: 60px 0 60px; }
    .banner.inner-page .banner-content > .container {
      height: 100%; }
      .banner.inner-page .banner-content > .container .row {
        height: 100%; }
    .banner.inner-page .banner-content .col-md-8 {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center; }
  @media (min-width: 992px) {
    .banner:not(.inner-page) {
      height: 650px; }
    .banner.inner-page {
      min-height: 350px; } }
  @media (min-width: 1500px) {
    .banner:not(.inner-page) {
      height: 760px; }
    .banner.inner-page {
      min-height: 450px; } }
  .banner h1.fc-white {
    font-size: 27px;
    line-height: 45px;
    padding-top: 40px; }
    @media (min-width: 992px) {
      .banner h1.fc-white {
        font-size: 42px;
        line-height: 60px; } }
  .banner button {
    margin-bottom: 40px; }
    @media (min-width: 992px) {
      .banner button {
        margin-bottom: 0; } }
  .banner .banner-content {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center; }
    .banner .banner-content .single-header {
      background: transparent; }
    .banner .banner-content:not(.other) {
      flex-direction: column;
      justify-content: space-between; }
  .banner .meta {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .banner .meta .bloger-img {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      border: 5px solid rgba(246, 248, 251, 0.12);
      background-size: cover !important;
      margin-right: 20px;
      flex: 0 0 68px; }
    .banner .meta span {
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF; }
      .banner .meta span a {
        color: #78b3e2; }
    .banner .meta .bar {
      margin: 0 15px;
      height: 35px;
      width: 1px;
      background: #FFFFFF; }
  @media (min-width: 1500px) {
    .banner .banner-menu.inner {
      margin-top: 20px; } }

.right-menu {
  display: flex;
  align-items: center; }
  .right-menu li {
    padding: 0 15px;
    border-right: 1px solid #FFFFFF; }
    .right-menu li:last-child {
      border-right: 0;
      padding-right: 0; }
    .right-menu li a {
      color: #FFFFFF;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 16px; }
      @media (min-width: 1500px) {
        .right-menu li a {
          font-size: 18px; } }
      .right-menu li a:hover {
        color: rgba(255, 255, 255, 0.8); }

.single-header {
  position: relative;
  width: 100%;
  background: #0c4e82; }
  @media (max-width: 767px) {
    .single-header .logo {
      width: 100px;
      height: 30px;
      background-size: 100px 20px; } }

.banner-menu {
  position: relative;
  background: rgba(51, 51, 51, 0.2);
  width: 100%; }
  .banner-menu.home {
    opacity: 0;
    transition: all .3s ease; }
    .banner-menu.home.show {
      opacity: 1; }
  .banner-menu ul {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap; }
  .banner-menu.inner {
    display: none;
    background: #FFFFFF; }
    .banner-menu.inner li {
      flex-grow: 1; }
      .banner-menu.inner li a {
        width: 100%; }
        @media (min-width: 1500px) {
          .banner-menu.inner li a {
            padding: 45px 10px; }
            .banner-menu.inner li a img {
              height: 70px; } }
        .banner-menu.inner li a img {
          height: 40px; }
        .banner-menu.inner li a span {
          color: #0c4e82; }
        .banner-menu.inner li a:hover span {
          color: #fdad20; }
  .banner-menu li.select a {
    background: #fdad20; }
    .banner-menu li.select a span {
      color: #0c4e82; }
    .banner-menu li.select a:hover span {
      color: #0c4e82; }
  .banner-menu li a {
    display: block;
    padding: 25px 10px 15px;
    width: 110px;
    text-align: center;
    height: 100%; }
    .banner-menu li a img {
      margin-bottom: 15px;
      height: 40px; }
    .banner-menu li a span {
      display: block;
      color: #FFFFFF;
      font-size: 14px;
      transition: all .3s ease; }
    @media (min-width: 1500px) {
      .banner-menu li a {
        padding: 35px 10px;
        width: 185px; }
        .banner-menu li a img {
          height: 73px; }
        .banner-menu li a span {
          font-size: 16px; } }

.trigger-category.active .hamburger-menu:before, .trigger-category.active .hamburger-menu:after, .trigger-category.active .hamburger-menu span {
  background: #fdad20; }

.provider-banner .trigger-category.active .hamburger-menu:before, .provider-banner .trigger-category.active .hamburger-menu:after,
.provider-banner .trigger-category.active .hamburger-menu span {
  background: #00a9dd; }

.hamburger-menu {
  position: relative;
  width: 20px;
  height: 18px; }
  .hamburger-menu.right {
    margin-left: 20px; }
  .hamburger-menu.left {
    margin-right: 15px; }
  .hamburger-menu:before, .hamburger-menu:after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    left: 0;
    background: #FFFFFF; }
  .hamburger-menu:after {
    bottom: 0;
    top: auto; }
  .hamburger-menu span {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #FFFFFF;
    top: 50%;
    left: 0;
    margin-top: -1px; }

.provider-profile {
  position: relative;
  width: 100%; }
  @media (max-width: 1199px) {
    .provider-profile:before {
      display: none; } }
  .provider-profile:before {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 163px;
    background: rgba(0, 0, 0, 0.2); }

.profile-details {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 100%;
  min-height: 390px;
  padding: 40px 0; }
  .profile-details .image {
    background-size: cover !important;
    width: 221px;
    height: 221px;
    flex: 0 0 221px;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .profile-details .image {
        margin-right: 45px;
        margin-bottom: 0; } }
  .profile-details .info-wrapper {
    display: flex; }
    @media (max-width: 991px) {
      .profile-details .info-wrapper {
        flex-wrap: wrap; } }
    .profile-details .info-wrapper h3 {
      font-size: 30px;
      color: #FFFFFF;
      margin-top: -10px;
      padding-bottom: 5px; }
    .profile-details .info-wrapper .info .location {
      padding-top: 10px; }
      .profile-details .info-wrapper .info .location ul {
        display: flex;
        align-items: center;
        justify-content: flex-start !important; }
      .profile-details .info-wrapper .info .location li {
        margin-right: 40px;
        display: flex;
        align-items: center; }
        .profile-details .info-wrapper .info .location li img {
          margin-right: 10px; }
        .profile-details .info-wrapper .info .location li, .profile-details .info-wrapper .info .location li a {
          color: #98cbf0;
          font-size: 18px; }
    .profile-details .info-wrapper .desc {
      padding-top: 20px; }
      @media (min-width: 992px) {
        .profile-details .info-wrapper .desc {
          padding-top: 56px; } }
      .profile-details .info-wrapper .desc p {
        font-size: 17px;
        color: #FFFFFF; }

.pb-0 {
  padding-bottom: 0 !important; }

.featured-in {
  position: relative;
  background: #f6f8fb;
  padding: 40px 0; }
  @media (min-width: 1500px) {
    .featured-in {
      padding: 75px 0; } }
  .featured-in h2 {
    padding-bottom: 20px; }
    @media (min-width: 1500px) {
      .featured-in h2 {
        padding-bottom: 50px; } }
  .featured-in img {
    margin-bottom: 20px;
    max-width: 100%; }
  .featured-in .feature-images {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    .featured-in .feature-images img {
      max-width: 200px;
      margin-bottom: 20px; }
      @media (min-width: 1200px) {
        .featured-in .feature-images img {
          margin-bottom: 0; } }

.title {
  position: relative;
  padding-bottom: 40px; }
  .title.center {
    text-align: center; }
    .title.center h2:before {
      left: 50%;
      margin-left: -65px; }
      @media (min-width: 1500px) {
        .title.center h2:before {
          margin-left: -93px; } }
  .title.flip-line h2:before {
    transform: scaleX(-1); }
  .title h2 {
    position: relative;
    padding-bottom: 40px; }
    @media (min-width: 1500px) {
      .title h2 {
        padding-bottom: 70px; } }
    .title h2:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: url("assets/img/regular/title-line.png") center center no-repeat;
      background-size: 130px 35px;
      width: 130px;
      height: 35px; }
      @media (min-width: 1500px) {
        .title h2:before {
          background-size: 186px 50px;
          width: 186px;
          height: 50px;
          padding-bottom: 70px; } }
  .title p {
    font-size: 18px;
    font-weight: 600;
    padding: 20px 0px 0; }
    @media (min-width: 1500px) {
      .title p {
        font-size: 22px;
        padding: 20px 40px 0; } }
    .title p.left-sub {
      padding: 20px 0 0; }

.sub {
  font-size: 18px;
  font-weight: 600;
  padding: 0px 0px 0; }
  @media (min-width: 1500px) {
    .sub {
      font-size: 22px; } }

.banner-title {
  position: relative;
  padding-bottom: 50px;
  padding-top: 0 !important; }
  @media (min-width: 1500px) {
    .banner-title {
      padding-bottom: 80px; } }
  .banner-title:before {
    content: '';
    position: absolute;
    bottom: 0;
    background: url("assets/img/regular/title-line.png") center center no-repeat;
    background-size: 130px 35px;
    width: 130px;
    height: 35px;
    left: 50%;
    margin-left: -65px; }
    @media (min-width: 1500px) {
      .banner-title:before {
        margin-left: -93px; } }
    @media (min-width: 1500px) {
      .banner-title:before {
        background-size: 186px 50px;
        width: 186px;
        height: 50px;
        padding-bottom: 70px; } }

section {
  position: relative;
  padding: 60px 0;
  background-size: cover !important; }
  section.bg-grey {
    background: #f6f8fb; }
  section.small {
    padding: 80px 0; }
  @media (min-width: 1500px) {
    section {
      padding: 130px 0; } }

.large-top-padding section, .large-top-padding .featured-in {
  padding-top: 200px !important; }

.feature-card {
  position: relative;
  transition: all 0.3s ease;
  border-radius: 0 0 8px 8px;
  margin-bottom: 25px; }
  @media (min-width: 992px) {
    .feature-card {
      margin-bottom: 0;
      height: 100%; } }
  .feature-card.blog {
    box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.08); }
    .feature-card.blog .icon-content {
      margin-bottom: 30px; }
    .feature-card.blog .details h4 {
      padding-bottom: 20px; }
  .feature-card:hover {
    box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.08); }
  .feature-card .icon-content {
    margin-bottom: 20px; }
    .feature-card .icon-content img {
      width: 100%; }
  .feature-card .details {
    padding: 0 20px 30px; }
    .feature-card .details p {
      word-break: break-all;
      padding-bottom: 20px; }

.real-talk-box {
  display: flex;
  flex-wrap: wrap; }
  .real-talk-box li {
    display: block;
    padding: 25px 5px 35px;
    width: 160px;
    text-align: center; }
    .real-talk-box li img {
      margin-bottom: 10px;
      height: 50px; }
      @media (min-width: 1500px) {
        .real-talk-box li img {
          height: 73px; } }
    .real-talk-box li span {
      display: block;
      font-size: 14px;
      line-height: 24px; }
      @media (min-width: 1500px) {
        .real-talk-box li span {
          font-size: 18px; } }

.tab-container {
  position: relative; }

.tabs {
  padding-right: 10px;
  margin-bottom: 30px; }
  .tabs li a {
    padding: 20px 15px;
    font-size: 16px;
    font-weight: bold;
    display: block;
    background: #0c4e82;
    color: #FFFFFF; }
    @media (min-width: 1500px) {
      .tabs li a {
        padding: 25px 15px;
        font-size: 22px; } }
    .tabs li a.inactive {
      background: #f6f8fb;
      color: #333333; }
      .tabs li a.inactive:hover {
        background: #e4eaf3; }

.tab-content p {
  margin-bottom: 30px; }

.latest-blog .title {
  padding-bottom: 0;
  padding-top: 20px; }

.latest-blog-list {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 0; }
  .latest-blog-list .image {
    width: 86px;
    height: 86px;
    background-size: cover !important;
    flex: 0 0 86px;
    margin-right: 25px;
    border-radius: 50%; }

.cta a {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600; }
  @media (min-width: 1500px) {
    .cta a {
      font-size: 24px; } }

.team {
  justify-content: center; }
  .team li {
    padding: 0 5px; }
    @media (min-width: 768px) {
      .team li {
        padding: 0 20px; } }
  .team img {
    width: 70px; }
    @media (min-width: 768px) {
      .team img {
        width: 100px; } }
  @media (min-width: 1500px) {
    .team {
      justify-content: space-around; }
      .team img {
        width: auto; } }

footer {
  position: relative; }
  footer .footer-link {
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (min-width: 992px) {
      footer .footer-link {
        padding: 70px 0; } }
    footer .footer-link ul {
      flex: 0 0 100%;
      margin-bottom: 20px; }
      @media (min-width: 768px) {
        footer .footer-link ul {
          flex: 0 0 25%;
          margin-bottom: 0; } }
      footer .footer-link ul a {
        font-family: "Muli", sans-serif;
        font-size: 16px;
        line-height: 32px;
        display: block;
        color: #333333; }
        @media (min-width: 1500px) {
          footer .footer-link ul a {
            font-size: 20px;
            line-height: 36px; } }
        footer .footer-link ul a:hover {
          color: #fdad20; }
  footer .copyright {
    padding: 30px 0;
    border-top: 1px solid #7d919c; }

.blog-content p {
  margin-bottom: 40px; }

.blog-content a {
  color: #333333;
  border-bottom: 2px solid #fdad20;
  padding-bottom: 2px; }

.blog-content h3 {
  font-size: 32px;
  padding-bottom: 20px; }

.blog-content .steps {
  position: relative;
  margin-bottom: 60px; }
  .blog-content .steps .step {
    position: relative;
    font-weight: bold;
    padding-left: 40px; }
    .blog-content .steps .step p {
      margin-bottom: 30px; }
    .blog-content .steps .step:before {
      position: absolute;
      content: '';
      width: 7px;
      height: 80%;
      background: #fdad20;
      left: 0;
      top: 10%; }
    .blog-content .steps .step:after {
      content: '';
      position: absolute;
      left: 7px;
      top: 50%;
      margin-top: -8px;
      border-left: 8px solid #fdad20;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent; }

.blog-content img {
  width: 100%;
  margin-bottom: 50px; }

.blog-content .lists {
  position: relative; }
  .blog-content .lists.question {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px; }
    .blog-content .lists.question li {
      width: 50%;
      padding-right: 30px; }
      .blog-content .lists.question li:before {
        background: url("assets/img/double/category/icon-ask_2x.png") center center no-repeat;
        border-radius: 50%;
        background-size: 32px  32px; }
  .blog-content .lists.half {
    display: flex;
    flex-wrap: wrap; }
    .blog-content .lists.half li {
      width: 50%;
      padding-right: 30px; }
  .blog-content .lists li {
    position: relative;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;
    padding-left: 60px;
    display: flex;
    align-items: center; }
    @media (min-width: 1500px) {
      .blog-content .lists li {
        font-size: 20px;
        line-height: 36px; } }
    .blog-content .lists li:before {
      position: absolute;
      left: 0;
      content: '';
      width: 32px;
      height: 33px;
      background: url("assets/img/regular/arrow.png") center center no-repeat;
      background-size: 32px 33px; }

.image-block {
  max-height: 170px;
  width: auto !important; }

.table-shadow {
  position: relative; }
  @media (min-width: 992px) {
    .table-shadow {
      margin-left: -70px;
      margin-right: -70px; } }
  .table-shadow:before, .table-shadow:after {
    content: '';
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    border-radius: 50%; }
  .table-shadow:before {
    top: 15%;
    left: 0;
    width: 5%;
    height: 70%; }
  .table-shadow:after {
    top: 15%;
    right: 0;
    width: 5%;
    height: 70%; }

.table-responsive {
  position: relative;
  overflow: auto;
  width: 100%;
  display: block;
  background: #FFFFFF;
  max-height: 700px;
  margin-bottom: 80px; }
  .table-responsive.alter {
    max-height: 1500px;
    margin-bottom: 0; }
    .table-responsive.alter table thead th img {
      max-height: 50px; }
    .table-responsive.alter table tbody tr td {
      padding-left: 25px !important;
      padding-right: 25px !important;
      min-width: 215px !important; }
  .table-responsive table {
    max-width: 1200px; }
    .table-responsive table:not(.alter) {
      max-width: 900px;
      padding: 30px; }

table {
  position: relative;
  width: 100%; }
  table.alter thead tr th {
    border: 0;
    background: #0c4e82;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 15px;
    padding-top: 15px;
    min-width: auto;
    padding-left: 50px;
    padding-right: 50px; }
    table.alter thead tr th:nth-of-type(2) {
      text-align: left; }
      table.alter thead tr th:nth-of-type(2) img {
        margin-left: 10px !important;
        margin-right: 0 !important; }
    table.alter thead tr th img {
      display: block;
      height: 44px;
      margin: 0 auto 10px !important; }
  table.alter tbody tr:nth-of-type(even) {
    background: #f0f2f4; }
  table.alter tbody tr:nth-of-type(odd) {
    background: #f9fafb; }
  table.alter tbody td {
    padding-left: 15px;
    padding-right: 15px; }
    table.alter tbody td:nth-of-type(1) {
      text-align: left;
      min-width: 530px; }
    table.alter tbody td:nth-of-type(2) {
      width: 100px; }
    table.alter tbody td .large {
      font-size: 50px;
      font-weight: bold; }
    table.alter tbody td p {
      padding-bottom: 15px;
      font-weight: bold; }
    table.alter tbody td ul li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px; }
      table.alter tbody td ul li:before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        background: url("assets/img/double/category/icon-check_2x.png");
        background-size: 15px 12px;
        height: 12px;
        width: 15px; }
  table.alter tbody th, table.alter tbody td {
    position: relative;
    min-width: auto; }
    table.alter tbody th:first-child, table.alter tbody td:first-child {
      text-align: center; }
    table.alter tbody th:nth-of-type(3), table.alter tbody th:nth-of-type(4), table.alter tbody td:nth-of-type(3), table.alter tbody td:nth-of-type(4) {
      padding-left: 10px; }
    table.alter tbody th .badge, table.alter tbody td .badge {
      position: absolute;
      left: 0;
      top: 30px;
      max-width: 200px; }
    table.alter tbody th img, table.alter tbody td img {
      height: 45px;
      margin: 0 auto; }
  table thead tr th {
    padding-top: 25px;
    padding-bottom: 45px;
    border-bottom: 2px solid #0c4e82; }
  table th, table td {
    padding: 20px 30px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    min-width: 239px; }
    @media (min-width: 1500px) {
      table th, table td {
        font-size: 18px; } }
    table th a, table td a {
      border: 0 !important;
      color: #0c4e82 !important;
      font-weight: bold; }
    table th img, table td img {
      margin-bottom: 0 !important; }
    table th:first-child, table td:first-child {
      text-align: left;
      min-width: 210px; }
  table tbody tr:nth-of-type(even) {
    background: #f0f2f4; }

.news-letter {
  position: relative;
  padding: 35px 35px;
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 4px;
  margin-bottom: -100px;
  margin-top: -50px;
  background: #FFFFFF;
  z-index: 1; }
  .news-letter .details {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }
    .news-letter .details img {
      width: 80px;
      flex: 0 0 80px;
      margin-right: 40px; }
    .news-letter .details h4 {
      font-size: 20px; }
    @media (min-width: 768px) {
      .news-letter .details {
        margin-bottom: 0px; }
        .news-letter .details img {
          width: 123px;
          flex: 0 0 123px; }
        .news-letter .details h4 {
          font-size: 24px; } }
  .news-letter input {
    height: 54px;
    width: 100%;
    padding: 10px 20px;
    border: 1px solid rgba(51, 51, 51, 0.2);
    margin-bottom: 20px;
    outline: 0;
    font-weight: normal !important;
    font-family: "Muli", sans-serif;
    box-shadow: none;
    transition: all .3s ease; }
    .news-letter input:focus {
      border-color: #0c4e82; }

.blog-list {
  position: relative; }
  .blog-list .list-single {
    background: #FFFFFF;
    margin-bottom: 30px;
    box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.08); }
    .blog-list .list-single .meta {
      display: flex;
      position: relative;
      padding-top: 20px;
      padding-bottom: 15px; }
      .blog-list .list-single .meta p {
        display: flex;
        align-items: center;
        color: #b9c0c9;
        margin-right: 30px; }
        .blog-list .list-single .meta p img {
          margin-right: 15px; }
    .blog-list .list-single.featured {
      padding-right: 20px;
      box-shadow: 0; }
      .blog-list .list-single.featured .image {
        background-size: cover !important;
        height: 100%;
        clip-path: circle(100% at 12% 50%); }
        .blog-list .list-single.featured .image .clip-path {
          right: -35px;
          bottom: auto;
          left: auto; }
      .blog-list .list-single.featured h4 {
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 15px; }
        .blog-list .list-single.featured h4:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 3px;
          bottom: 0;
          background-image: linear-gradient(to right, #0c4e82 50%, #fdad20 50%); }
    .blog-list .list-single h4 {
      position: relative;
      padding-bottom: 20px; }
    .blog-list .list-single p {
      font-size: 18px; }
    .blog-list .list-single .image {
      position: relative;
      overflow: hidden;
      height: 220px;
      background-size: cover !important;
      clip-path: circle(159.4% at 50% -132%); }
      .blog-list .list-single .image img {
        width: 100%;
        clip-path: circle(159.4% at 50% -132%); }
      .blog-list .list-single .image .clip-path {
        width: 200%;
        height: 200%;
        border: 60px solid #fff;
        position: absolute;
        bottom: -45px;
        left: -50%;
        border-radius: 50%; }
    .blog-list .list-single .cta {
      font-weight: bold;
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 40px;
      display: block; }
    .blog-list .list-single .feature-card {
      text-align: left; }
    .blog-list .list-single .details {
      padding: 20px 32px 30px; }
      .blog-list .list-single .details h4 {
        padding-bottom: 5px; }
      .blog-list .list-single .details .meta {
        padding-top: 0; }
        .blog-list .list-single .details .meta img {
          margin-right: 10px; }
        .blog-list .list-single .details .meta p {
          margin-right: 15px; }

.load-more {
  position: relative;
  padding: 100px 0 0;
  width: 100%;
  z-index: 5;
  margin-top: -50px; }

.card {
  position: relative;
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  display: block;
  overflow: hidden;
  box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 30px; }
  @media (min-width: 1500px) {
    .card {
      margin-bottom: 0; } }
  .card .bg-block {
    padding: 10px 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 210px; }
    .card .bg-block.green {
      background: #3b7eff; }
    .card .bg-block.red {
      background: #ef5b51; }
    .card .bg-block.yellow {
      background: #fdad20; }
      .card .bg-block.yellow > img {
        margin-top: -20px; }
    .card .bg-block.white-star .stars a {
      background: #FFFFFF;
      color: #fdad20; }
    .card .bg-block > img {
      margin-bottom: 30px; }
    .card .bg-block .clip-path {
      width: 200%;
      height: 200%;
      border: 40px solid #fff;
      position: absolute;
      bottom: -35px;
      left: -50%;
      border-radius: 50%; }
    .card .bg-block .stars li {
      padding: 0 3px; }
    .card .bg-block .stars a {
      width: 100px;
      height: 30px;
      background: #fdad20;
      text-transform: uppercase;
      font-weight: bold;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin-left: 15px;
      font-size: 18px; }
  .card .details {
    padding: 30px; }
    .card .details .author {
      font-weight: bold;
      padding-top: 5px; }
    .card .details a {
      font-size: 18px;
      text-align: right;
      display: block;
      font-weight: bold;
      margin-bottom: 15px; }

.review-card {
  position: relative;
  display: flex;
  border-radius: 8px;
  background: #FFFFFF;
  overflow: hidden;
  margin-bottom: 35px; }
  @media (max-width: 991px) {
    .review-card {
      flex-wrap: wrap; } }
  .review-card .image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0c4e82;
    padding: 10px;
    flex: 0 0 100%; }
    @media (min-width: 992px) {
      .review-card .image-container {
        flex: 0 0 185px; } }
    .review-card .image-container .image {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      border: 3px solid #88ace3; }
  .review-card .review-details {
    position: relative;
    padding: 30px;
    width: 100%;
    box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.08); }
    .review-card .review-details .title-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; }
      .review-card .review-details .title-header h4 {
        font-size: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 320px; }
      .review-card .review-details .title-header .cta {
        display: flex;
        align-items: center; }
      .review-card .review-details .title-header .link {
        margin-left: 10px;
        font-size: 16px;
        height: 30px;
        border: 1px solid #fdad20;
        font-weight: normal !important;
        border-radius: 30px;
        padding-left: 10px;
        padding-right: 10px;
        font-family: "Muli", sans-serif;
        display: flex;
        align-items: center; }

.review-count {
  position: relative;
  display: flex;
  padding-top: 10px;
  padding-bottom: 15px; }
  .review-count li {
    padding: 0 2px; }
  .review-count ul {
    margin-right: 20px; }
  .review-count span {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #b9c0c9; }
    .review-count span img {
      margin-right: 10px; }
  .review-count p {
    font-size: 18px; }

.recomend {
  font-weight: bold;
  padding-top: 20px; }
  .recomend .yes {
    color: #5cb100; }

.recommend {
  position: relative;
  background: #FFFFFF;
  padding: 10px;
  box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  border-radius: 4px;
  margin-bottom: 35px; }
  .recommend .single {
    flex-grow: 1;
    padding: 10px 0;
    text-align: center;
    border-right: 1px solid #b9c0c9; }
    .recommend .single:last-child {
      border-right: 0; }
    .recommend .single h4 {
      font-size: 30px;
      color: #1a5889; }
    .recommend .single p {
      font-size: 16px;
      font-weight: bold; }

.overall {
  position: relative;
  width: 100%;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.08); }
  .overall .header {
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #b9c0c9; }
    .overall .header h5 {
      text-transform: uppercase;
      padding-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      display: block; }
    .overall .header h4 {
      font-size: 50px;
      color: #fdad20;
      padding-bottom: 15px;
      display: block; }
  .overall ul {
    align-items: center;
    justify-content: center; }
  .overall li {
    padding: 0 2px; }
  .overall .body, .overall h4, .overall .fs-4, .overall h5, .overall p, .overall .fs-5, .overall h6, .overall .fs-6, .overall small, .overall .fs-8 {
    display: flex;
    flex-wrap: wrap; }
    .overall .body .single, .overall h4 .single, .overall .fs-4 .single, .overall h5 .single, .overall p .single, .overall .fs-5 .single, .overall h6 .single, .overall .fs-6 .single, .overall small .single, .overall .fs-8 .single {
      width: 50%;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center; }
      .overall .body .single p, .overall h4 .single p, .overall .fs-4 .single p, .overall h5 .single p, .overall p .single p, .overall .fs-5 .single p, .overall h6 .single p, .overall .fs-6 .single p, .overall small .single p, .overall .fs-8 .single p {
        font-size: 16px;
        padding-bottom: 0px;
        text-align: center; }
      .overall .body .single li, .overall h4 .single li, .overall .fs-4 .single li, .overall h5 .single li, .overall p .single li, .overall .fs-5 .single li, .overall h6 .single li, .overall .fs-6 .single li, .overall small .single li, .overall .fs-8 .single li {
        padding: 0 1px; }
        .overall .body .single li img, .overall h4 .single li img, .overall .fs-4 .single li img, .overall h5 .single li img, .overall p .single li img, .overall .fs-5 .single li img, .overall h6 .single li img, .overall .fs-6 .single li img, .overall small .single li img, .overall .fs-8 .single li img {
          width: 17px; }

.company-offers {
  position: relative; }
  .company-offers:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 30px;
    background: url("assets/img/regular/shape-secondary.png") center center no-repeat; }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.hidden-xxs-up {
  display: none !important; }

@media (max-width: 399px) {
  .hidden-xxs-down {
    display: none !important; } }

@media (min-width: 400px) {
  .hidden-xs-up {
    display: none !important; } }

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

@media (max-width: 1499px) {
  .hidden-xl-down {
    display: none !important; } }

@media (min-width: 1500px) {
  .hidden-xxl-up {
    display: none !important; } }

.hidden-xxl-down {
  display: none !important; }

@media (max-width: 400px) {
  .hidden-xxs-only {
    display: none !important; } }

@media (min-width: 400px) and (max-width: 575px) {
  .hidden-xs-only {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm-only {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md-only {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg-only {
    display: none !important; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .hidden-xl-only {
    display: none !important; } }

@media (min-width: 1500px) {
  .hidden-xxl-only {
    display: none !important; } }
