/////////////////////////////////////////////////////////
// Button                                              //
/////////////////////////////////////////////////////////

// Button Defaults
$btn-height: 88px!default;
$btn-height-mobile: 55px!default;
$btn-min-width: 10px!default;
$btn-border-width: 1px!default;
$btn-border-radius: 50px!default;
$btn-font-family: $hfont!default;
$btn-font-size: 24px!default;
$btn-font-weight: 600!default;
$btn-text-transform: capitalize!default;
$btn-transition: all 300ms linear!default;
$btn-display: inline-block!default;

//// Button height is not set, but calculated by the line-height + padding top & bottom.
//// The top/bottom padding is calculated based on the desired btn height
$btn-max-height: $btn-height - ($btn-border-width * 2)!default;
$btn-font-size: 16px!default;
$btn-line-height: $btn-font-size + 4!default;
$btn-padding:(($btn-max-height / 2) - ($btn-line-height / 2)) ($btn-line-height * 2.2)!default;

$btn-max-height-mobile: $btn-height-mobile - ($btn-border-width * 2)!default;
$btn-padding-mobile: (($btn-max-height-mobile / 2) - ($btn-line-height / 2)) ($btn-line-height * 2.2)!default;

//// Base Button color
$btn-font-color: white!default;
$btn-bgcolor: $primary!default;
//// Button color variations
//// Name: (Background,Font,Border)
$buttons: (
	primary: ($primary, $white, $primary),
	secondary: ($secondary, $white, $secondary),
	black: ($black,$white,$black),
	darkgrey: ($darkgrey,$white,$darkgrey),
	lightgrey: ($lightgrey,$black,$lightgrey),
	white: ($white,$primary,$white),
	danger: ($error,$white,$error),
	warning: ($warning,$white,$warning),
	success: ($success,$white,$success)
)!default;


// Buttons
.btn,
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
	@include transition($btn-transition);
	@include border-radius($btn-border-radius);
	display: $btn-display;
	padding: $btn-padding-mobile;

	font-family: $btn-font-family;
	font-size: $btn-font-size - 7;
	line-height: $btn-line-height;
	font-weight: $btn-font-weight;
	text-transform: $btn-text-transform;
	color: $btn-font-color;
	background-color: $btn-bgcolor;
	background-clip: border-box;
	border:$btn-border-width solid $btn-bgcolor;
	min-width: $btn-min-width;
	text-decoration: none;
	text-align: center;
	width: auto;
	appearance: none;
	cursor: pointer;
	box-sizing: border-box;
	-webkit-appearance: none;
	@include media-breakpoint-down(md) {
		padding-left: 30px;
		padding-right: 30px;
	}
	@include media-breakpoint-up(xxl) {
		padding: $btn-padding;
		font-size: $btn-font-size;
	}
}
.btn,
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
	@each $name, $colors in $buttons {
		$bgcolor: nth($colors, 1);
		$fontcolor: nth($colors, 2);
		$bordercolor: nth($colors, 3);
		&.btn-#{$name} {
			background-color: $bgcolor;
			color: $fontcolor;
			border-color: $bordercolor;
			&.btn-outline {
				background-color: transparent;
				color: $bordercolor;
			}
		}
		@media (hover: hover) {
			&.btn-#{$name} {
				&:hover,&:focus,&:active,&.active,&.is-active {
					border: $btn-border-width solid lighten($bgcolor, 10%);
					background: lighten($bgcolor, 10%);
					&.btn-outline {
						border:$btn-border-width solid $bordercolor;
						background:$bordercolor;
						color: $fontcolor;
					}
				}
			}
		}
	}
}


// Btn Width & Display modifiers
.btn,
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
	&.btn-wide {display:block;}
	.icon {
		position: relative;
		top:2px;
	}
}

.btn-small {
	height: 58px;
	font-size: 18px;
	padding: 0;
	padding-left: 30px;
	padding-right: 30px;

	@include media-breakpoint-up(xxl) {
		font-size: 24px;
	}

	&.block {
		width: 100% !important;
	}

}

.btn-inverse {
	min-width: 300px;
	height: 50px;
	border: 2px solid $primary;
	background: transparent;
	color: $primary;
	padding: 0;
	font-size: 16px;

	@include media-breakpoint-up(xxl) {
		min-width: 396px;
		height: 70px;
		border: 3px solid $primary;
		font-size: 24px;
	}
	&:hover {
		background: $primary;
		color: $white;
	}
}


// Disabled
button[disabled], .btn-disabled {
    background:$disabled!important;
    border: $btn-border-width solid $disabled!important;
    color: white!important;
    cursor: not-allowed!important;
    border-color: $disabled!important;
}
