.blog-list {
	position: relative;
	.list-single {
		background: $white;
		margin-bottom: 30px;
		.meta {
			display: flex;
			position: relative;
			padding-top: 20px;
			padding-bottom: 15px;
			p {
				display: flex;
				align-items: center;
				color: #b9c0c9;
				margin-right: 30px;
				img {
					margin-right: 15px;
				}
			}
		}


		&.featured {
			padding-right: 20px;
			box-shadow: 0;;
			.image {
				background-size: cover !important;
				height: 100%;
				clip-path: circle(100.0% at 12% 50%);

				.clip-path {
					right: -35px;
					bottom: auto;
					left: auto;
				}
			}
			h4 {
				position: relative;
				padding-bottom: 20px;
				margin-bottom: 15px;

				&:before {
					position: absolute;
					content: '';
					width: 100%;
					height: 3px;
					bottom: 0;
					background-image: linear-gradient(to right, $tartiary 50%, $primary 50%);
				}
			}
		}

		h4 {
			position: relative;
			padding-bottom: 20px;
		}
		p {
			font-size: 18px;
		}

		.image {
			position: relative;
			overflow: hidden;
			height: 220px;
			background-size: cover!important;
			clip-path: circle(159.4% at 50% -132%);
			img {
				width: 100%;
				clip-path: circle(159.4% at 50% -132%);
			}
			// &:before {
			// 	content: '';
			// 	position: absolute;
			// 	right: -40px;
			// 	width: 0;
			// 	height: 100%;
			// 	border-bottom: 70px solid transparent;
			// 	border-right: 100px solid red;
			// 	border-top: 70px solid transparent;
			// 	border-radius: 50%;;
			// }

			.clip-path {
			width: 200%;
			height: 200%;
			border: 60px solid #fff;
			position: absolute;
			bottom: -45px;
			left: -50%;
			border-radius: 50%;
			}
		}

		.cta {
			font-weight: bold;
			font-size: 16px;
			margin-top: 30px;
			margin-bottom: 40px;
			display: block;
		}

		box-shadow: 0 0 49px 0 rgba(0,0, 0, .08);

		.feature-card {
			text-align: left;;
		}

		.details {
			padding: 20px 32px 30px;
			h4 {
				padding-bottom: 5px;
			}
			.meta {
				padding-top: 0;
				img {
					margin-right: 10px;
				}
				p {
					margin-right: 15px;

				}
			}
		}
	}
}

.load-more {
	position: relative;
	padding: 100px 0 0;
	width: 100%;
	z-index: 5;
	// background-image: linear-gradient(to top, rgba($white, 1) 90%, rgba($white, 0) 2%);
	margin-top: -50px
}
