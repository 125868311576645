.real-talk-box {
	display: flex;
	flex-wrap: wrap;

	li {
		display: block;
		padding: 25px 5px 35px;
		width: 160px;
		text-align: center;
		img {
			margin-bottom: 10px;
			height: 50px;
			@include media-breakpoint-up(xxl) {
				height: 73px;
			}
		}
		span {
			display: block;
			font-size: 14px;
			line-height: 24px;
			@include media-breakpoint-up(xxl) {
				font-size: 18px;
			}
		}
	}
}
