$banner-height: 760px!default;

$banner-title-fz: 42px!default;
$banner-title-lh: 60px!default;
.banner {
	position: relative;
	@include background-cover;
	height: auto;



	header {width: 100%;}

	&.single {
		.banner-content {
			padding-top: 120px;
			padding-bottom: 60px;
		}
		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			background: rgba($tartiary, .77);
		}
	}

	&.inner-page {
		header {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
		}

		.banner-menu {
			img {
				@include media-breakpoint-up(xxl) {
					height: 70px;
				}
			}
		}

		.banner-content {
			display: block;
			padding: 60px 0 60px;

			> .container {
				height: 100%;
				.row {
					height: 100%;
				}
			}

			.col-md-8 {
				display: flex;
				height: 100%;
				align-items: center;
				justify-content: center;
			}
		}
	}



	@include media-breakpoint-up(lg) {
		&:not(.inner-page) {
			height: 650px;
		}
		&.inner-page {
			min-height: 350px;
		}
	}

	@include media-breakpoint-up(xxl) {
		&:not(.inner-page) {
			height: $banner-height;
		}
		&.inner-page {
			min-height: 450px;
		}
	}

	h1.fc-white {
		font-size: $banner-title-fz - 15px;
		line-height: $banner-title-lh - 15px;
		padding-top: 40px;

		@include media-breakpoint-up(lg) {
			font-size: $banner-title-fz;
			line-height: $banner-title-lh;
		}
	}

	button {
		margin-bottom: 40px;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}
	}

	.banner-content {
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
		.single-header {
			background: transparent;
		}
		&:not(.other) {
			flex-direction: column;
			justify-content: space-between;
		}

	}


	.meta {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		.bloger-img {
			width: 68px;
			height: 68px;
			border-radius: 50%;
			border: 5px solid rgba(#f6f8fb, .12);
			background-size: cover!important;
			margin-right: 20px;
			flex: 0 0 68px;
		}

		span {
			font-weight: bold;
			font-size: 16px;
			color: $white;
			a {
				color: #78b3e2;
			}
		}
		.bar {
			margin: 0 15px;
			height: 35px;
			width: 1px;
			background: $white;;
		}
	}
	.banner-menu.inner {
		@include media-breakpoint-up(xxl) {
			margin-top: 20px;

		}
	}
}

.right-menu {
	display: flex;
	align-items: center;

	li {
		padding: 0 15px;
		border-right: 1px solid $white;
		&:last-child {
			border-right: 0;
			padding-right: 0;
		}

		a {
			color: $white;
			display: flex;
			align-items: center;
			text-transform: uppercase;
			font-size: 16px;
			@include media-breakpoint-up(xxl) {
				font-size: 18px;
			}
			&:hover {
				color: rgba($white, .8);
			}
		}
	}
}

.single-header {
	position: relative;
	width: 100%;
	background: $tartiary;

	.logo {
		@include media-breakpoint-down(sm) {
			width: 100px;
			height: 30px;
			background-size: 100px 20px;

		}
	}
}


.banner-menu {
	position: relative;
	background: rgba($secondary, .2);
	width: 100%;
	&.home {
		opacity: 0;
		transition: all .3s ease;
		&.show {
			opacity: 1;
		}

	}
	ul {
		display: flex;
		justify-content: center;
		align-items: stretch;
		flex-wrap: wrap;
	}

	&.inner {

		display: none;
		background: $white;
		li {
			flex-grow: 1;
			a {
				width: 100%;
				@include media-breakpoint-up(xxl) {
					padding: 45px 10px;
					img {
						height: 70px;
					}

				}
				img {
					height: 40px
				}
				span {
					color: $tartiary
				}

				&:hover {
					span {
						color: $primary
					}
				}
			}
		}
	}

	li {
		&.select {
			a {
				background: $primary;
				span {
					color: $tartiary;

				}
				&:hover {
					span {
						color: $tartiary;
					}
				}
			}
		}
		a {
			display: block;
			padding: 25px 10px 15px;
			width: 110px;
			text-align: center;
			height: 100%;
			img {
				margin-bottom: 15px;
				height: 40px;
			}
			span {
				display: block;
				color: $white;
				font-size: 14px;
				transition: all .3s ease;
			}

			@include media-breakpoint-up(xxl) {
				padding: 35px 10px;
				width: 185px;

				img {
					height: 73px;
				}
				span {
					font-size: 16px;
				}

			}

		}
	}
}

.trigger-category.active {
	.hamburger-menu {
		&:before, &:after, span {
			background: $primary;
		}
	}
}

.provider-banner {
	.trigger-category.active {
		.hamburger-menu {
			&:before,
			&:after,
			span {
				background: #00a9dd;
			}
		}
	}

}

.hamburger-menu {
	position: relative;
	width: 20px;
	height: 18px;
	&.right {
		margin-left: 20px;;
	}
	&.left {
		margin-right: 15px;
	}
	&:before, &:after {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		height: 2px;
		left: 0;
		background: $white;
	}
	&:after {
		bottom: 0;
		top: auto;
	}
	span {
		position: absolute;
		width: 100%;
		height: 2px;
		background: $white;
		top: 50%;
		left: 0;
		margin-top: -1px;
	}
}

.provider-profile {
	position: relative;
	width: 100%;
	@include media-breakpoint-down(lg) {
		&:before {display: none;}
	}

	&:before {
		position: absolute;
		content: '';
		bottom: 0;
		width: 100%;
		height: 163px;
		background: rgba(0,0,0, .2);
	}
}

.profile-details {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	height: 100%;
	min-height: 390px;
	padding: 40px 0;

	.image {
		background-size: cover !important;
		width: 221px;
		height: 221px;
		flex: 0 0 221px;
		margin-bottom: 30px;
		@include media-breakpoint-up(md) {
			margin-right: 45px;
			margin-bottom: 0;
		}
	}

	.info-wrapper {
		display: flex;
		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
		}
		h3 {
			font-size: 30px;
			color: $white;
			margin-top: -10px;
			padding-bottom: 5px;
		}

		.info .location {
			padding-top: 10px;
			ul {display: flex;
				align-items: center;
				justify-content: flex-start !important}
			li {
				margin-right: 40px;
				display: flex;
				align-items: center;
				img {margin-right: 10px;}
				&, a {
					color: #98cbf0;
					font-size: 18px;
				}
			}
		}
		.desc {
			padding-top: 20px;
			@include media-breakpoint-up(lg) {
				padding-top: 56px;
			}
			p {
				font-size: 17px;
				color: $white;
			}
		}
	}
}

.pb-0 {
	padding-bottom: 0 !important;
}
