$featur-bg: $line!default;
$feature-padding: 75px 0!default;
$feature-padding-mobile: 40px 0!default;

$feature-title-padding: 50px!default;
.featured-in {
	position: relative;
	background: $featur-bg;
	padding: $feature-padding-mobile;
	@include media-breakpoint-up(xxl) {
		padding: $feature-padding;
	}

	h2 {
		padding-bottom: 20px;
		@include media-breakpoint-up(xxl) {
			padding-bottom: $feature-title-padding;
		}
	}
	img {
		margin-bottom: 20px;
		max-width: 100%;
		@include media-breakpoint-up(xxl) {

		}
	}
	.feature-images {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		img {
			max-width: 200px;
			margin-bottom: 20px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 0;

			}
		}
	}

}
