.news-letter {
	position: relative;
	padding: 35px 35px;
	border: 1px solid rgba($headingcolor, .2);
	border-radius: 4px;
	margin-bottom: -100px;
	margin-top: -50px;
	background: $white;
	z-index: 1;


	.details {
		display: flex;
		align-items: center;
		margin-bottom: 40px;



		img {
			width: 80px;
			flex: 0 0 80px;
			margin-right: 40px;
		}
		h4 {
			font-size: 20px;
		}

		@include media-breakpoint-up(md) {
			margin-bottom: 0px;
			img {
				width: 123px;
				flex: 0 0 123px;
			}
			h4 {
				font-size: 24px;
			}
		}
	}

	input {
		height: 54px;
		width: 100%;
		padding: 10px 20px;
		border: 1px solid rgba($headingcolor, .2);
		margin-bottom: 20px;
		outline: 0;
		font-weight: normal !important;
		font-family: $bfont;
		box-shadow: none;
		transition: all .3s ease;
		&:focus {
			border-color: $tartiary;
		}
	}
}
