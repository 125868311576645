/////////////////////////////////////////////////////////
// Tables                                              //
/////////////////////////////////////////////////////////
.table-shadow {
	position: relative;
	@include media-breakpoint-up(lg) {
		margin-left: -70px;
		margin-right: -70px;
	}

&:before, &:after {
		content: '';
		position: absolute;
		z-index: -1;
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); border-radius: 50%;
	}
	&:before {
		top: 15%; left: 0; width: 5%; height: 70%
	}
	&:after {
		top: 15%; right: 0; width: 5%; height: 70%;
	}
}
.table-responsive {
	position: relative;
	overflow: auto;
	width: 100%;
	display: block;
	background: $white;

	max-height: 700px;
	margin-bottom: 80px;

	&.alter {
		max-height: 1500px;
		margin-bottom: 0;



		table {
			thead th {
				img {
					max-height: 50px;
				}
			}
				tbody tr td {
					padding-left: 25px !important;;
					padding-right: 25px !important;
					min-width: 215px !important;
				}
		}
	}



	table {
		&:not(.alter) {
			max-width: 900px;
			padding: 30px;
		}
		max-width: 1200px;
	}
}

table {
	position: relative;
	width: 100%;

	&.alter {
		thead {
			tr th {
				border: 0;
				background: $tartiary;
				color: $white;
				text-align: center;;
				padding-bottom: 15px;
				padding-top: 15px;
				min-width: auto;
				padding-left: 50px;
				padding-right: 50px;
				&:nth-of-type(2) {
					text-align: left;
					img {
						margin-left: 10px!important;
						margin-right: 0 !important
					}
				}

				img {
					display: block;
					height: 44px;
					margin: 0 auto 10px !important;

				}
			}
		}
		tbody {
			tr:nth-of-type(even) {
				background: #f0f2f4;
			}
			tr:nth-of-type(odd) {
				background: #f9fafb;
			}
			td {
				padding-left: 15px;
				padding-right: 15px;
				&:nth-of-type(1) {
					text-align: left;
					min-width: 530px;
				}
				&:nth-of-type(2) {
					width: 100px;
				}

				.large {
					font-size: 50px;
					font-weight: bold;
				}

				p {padding-bottom: 15px; font-weight: bold;}
				ul {
					li {
						position: relative;
						padding-left: 30px;
						margin-bottom: 10px;
						&:before {
							content: '';
							position: absolute;
							left: 0;
							top: 8px;
							background: url('assets/img/double/category/icon-check_2x.png');
							background-size: 15px 12px;;
							height: 12px;
							width: 15px;
						}
					}
				}
			}
			th, td{
				position: relative;
				min-width: auto;
				&:first-child {
					text-align: center;
				}
				&:nth-of-type(3), &:nth-of-type(4) {
					padding-left: 10px;

				}

				.badge {
					position: absolute;
					left: 0;
					top: 30px;
					max-width: 200px;
				}
				img {
					height: 45px;
					margin: 0 auto;

				}
			}
		}
	}

	thead {
		tr th {
			padding-top: 25px;
			padding-bottom: 45px;
			border-bottom: 2px solid $tartiary;
		}
	}

	th,	td {
		padding: 20px 30px;
		text-align: center;
		line-height: 24px;
			font-size: 16px;
		min-width: 239px;
		@include media-breakpoint-up(xxl) {
			font-size: 18px;
		}
		a {
			border: 0!important;
			color: $tartiary !important;
			font-weight: bold;
		}
		img {
			margin-bottom: 0 !important;
		}
		&:first-child {
			text-align: left;
			min-width: 210px;;
		}
	}

	tbody tr:nth-of-type(even) {
		background: #f0f2f4;
	}
}
